import { Link, useRouteMatch } from 'react-router-dom';

import { DefaultLayout } from 'PayMyBill/core/layouts';

interface ParamsProps {
  accountId: string;
}

export const CompletePage: React.FC = () => {
  const {
    params: { accountId },
  } = useRouteMatch<ParamsProps>();

  return (
    <DefaultLayout>
      <div className="checkout-complete-container">
        <h2>Complete</h2>
        <p>Your account has been settled</p>
        <p>A receipt has been sent to the email address provided</p>
        <p className="order-number">Account #{accountId}</p>
        <Link to="/pay-my-bill" className="btn btn-primary">
          Back to Homepage
        </Link>
      </div>
    </DefaultLayout>
  );
};
