import { useConfig } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';
import FuzzySearch from 'fuzzy-search';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { CombinedVenue } from 'types/models';
import { googlePredictions } from 'venue/googlePredictions';
import { getSupportedServices } from 'venue/utils';

interface UseVenueSearchProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  filteredVenueList: CombinedVenue[];
  filteredVenueMap: CombinedVenue[];
  searchVenues: (searchTerm: string, isPrediction: boolean) => void;
  predictions: google.maps.places.AutocompletePrediction[];
  dropPredictions: () => void;
}
export const useVenueSearch = (): UseVenueSearchProps => {
  const {
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    amountOfVenuesToShow,
    showVenueHomeBanners,
  } = useConfig();
  const { venues: allVenues } = useVenues();

  const [searchTerm, setSearchTerm] = useState('');
  const [isPrediction, setIsPrediction] = useState(false);
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [filteredVenueList, setFilteredVenueList] = useState<CombinedVenue[]>(
    [],
  );
  const [filteredVenueMap, setFilteredVenueMap] = useState<CombinedVenue[]>([]);

  const searchVenues = (term: string, prediction: boolean) => {
    const delayedSearch = debounce((term) => {
      handleGoogleSearch(term);
    }, 300);

    if (prediction) {
      delayedSearch(term);
    }
    setIsPrediction(prediction);
    setSearchTerm(term);
  };

  const handleGoogleSearch = (term: string) => {
    const googleTerm = term.replace(/ /g, '+');

    googlePredictions(googleTerm).then((response) => {
      if (response) {
        setPredictions(response);
      }
    });
  };

  const dropPredictions = () => {
    setPredictions([]);
  };

  useEffect(() => {
    const sortVenues = (filteredVenues: CombinedVenue[]) => {
      return filteredVenues.sort((a, b) => {
        if (a.distance === -1 && b.distance > 0) return 1;
        if (a.distance > 0 && b.distance === -1) return -1;
        if (a.distance > 0 && b.distance > 0) return a.distance - b.distance;

        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    };

    const filterByTerm = (venues: CombinedVenue[], searchTerm: string) => {
      const searcher = new FuzzySearch(
        venues,
        [
          'name',
          'address.line1',
          'address.line2',
          'address.line3',
          'address.town',
          'address.county',
          'address.postcode',
        ],
        {
          caseSensitive: false,
        },
      );
      return searcher.search(searchTerm);
    };

    const filterVenues = (venues: CombinedVenue[], searchTerm: string) => {
      // filter by text
      const filteredVenues =
        isPrediction === false && searchTerm !== ''
          ? filterByTerm(venues, searchTerm)
          : venues;

      const supportedVenues = showVenueHomeBanners
        ? filteredVenues
        : filteredVenues.filter(
            (venue: CombinedVenue) =>
              getSupportedServices(
                venue.services,
                enableOrderAndPay,
                enableClickAndCollect,
                enableDeliveryLocation,
              ).length > 0,
          );

      const truncatedList =
        supportedVenues.slice(0, amountOfVenuesToShow) || 30;

      return sortVenues(truncatedList);
    };

    const filteredVenues =
      allVenues && allVenues.length > 0
        ? filterVenues(allVenues, searchTerm)
        : [];
    setFilteredVenueList(filteredVenues.slice(0, amountOfVenuesToShow || 30));
    setFilteredVenueMap(filteredVenues.slice(0, 1000));
  }, [
    allVenues,
    amountOfVenuesToShow,
    enableClickAndCollect,
    enableDeliveryLocation,
    enableOrderAndPay,
    isPrediction,
    searchTerm,
  ]);

  return {
    searchTerm,
    setSearchTerm,
    filteredVenueList,
    filteredVenueMap,
    searchVenues,
    predictions,
    dropPredictions,
  };
};
