import {
  Birthday,
  CardNumber,
  Checkbox,
  ConfirmPassword,
  HTMLField,
  Select,
  Text,
} from 'core/components/FormFields';
import { Email, Password } from 'core/components/OldForm';

import { Field } from 'types/models/Forms';

interface FieldGeneratorProps {
  field: Field;
}
/**
 * @deprecated
 */
export const FieldGenerator: React.FC<FieldGeneratorProps> = ({ field }) => {
  switch (field.type) {
    case 'checkbox':
      return <Checkbox field={field} />;

    case 'email':
      return <Email field={field} />;

    case 'password':
      return <Password field={field} />;

    case 'select':
      return <Select field={field} />;

    case 'text':
      return <Text field={field} />;

    case 'html':
      return <HTMLField field={field} />;

    case 'birthday':
    case 'date':
      return <Birthday field={field} />;

    case 'confirmPassword':
      return <ConfirmPassword field={field} />;

    case 'cardNumber':
    case 'loyalty_card':
      return <CardNumber field={field} />;

    default:
      return null;
  }
};
