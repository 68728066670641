import { FormattedCurrency } from 'common/components/FormattedCurrency';

export const BillTableTaxRow = ({ name, amount }) => {
  console.log('tax line');
  return (
    <div className={`bill-table-row`}>
      <div>{name}</div>
      <div className="text-right">
        <FormattedCurrency value={amount} />
      </div>
    </div>
  );
};
