import {
  MenuChoiceCustomiser,
  MenuChoiceSelector,
  ChoicePriceLabel,
} from 'menu/components/MenuModal/ModalComponents';
import { useConfig } from 'contexts/ConfigContext';
import { Choice, ChoiceGroup, DisplayRecord } from 'types/models';
import {
  countChoiceGroupSelectionQuantities,
  doesPortionHaveValidPrice,
  getChildPortionId,
  getChoiceGroupType,
  getPriceForSupplement,
} from 'menu/utils';
import { MenuItemDescription } from 'menu/components/MenuItemDescription';
import { CalorieAmountDisplay } from 'menu/components/Calories';

/**
 * Restructuring logic to start from choice group...
 *
 * *** If ChoiceGroup is Required Single Choice ***
 *
 * - Display Radio Box
 * - If choice leads to a sub-choice group or to a product which has further choices,
 *    display a customise button if selected (if selected, display subchoice in parenthesis)
 *
 * *** If ChoiceGroup is Optional Single Choice ***
 *
 * - Display Checkbox
 * - If choice leads to a sub-choice group or to a product which has further choices,
 *    display a customise button if selected (if selected, display subchoice in parenthesis)
 *
 * *** If ChoiceGroup is Multiple Choice ***
 *
 * - Display Checkbox
 * - If selected, and has no sub-choices etc, display quantity selector
 * - If selected, and has sub-choices etc, display customise button and list sub choices with
 *    quantities in parenthesis
 */

interface MenuChoiceProps {
  choice: Choice;
  choiceGroup: ChoiceGroup;
  portionId: number;
  itemsInChoiceGroup: number;
  choiceIndex: number;
  choiceGroupIndex: number;
  updateChoice: (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
    hasNestedChoice?: boolean,
  ) => void;
  isNestedChoice?: boolean;
}

export const MenuChoice: React.FC<MenuChoiceProps> = ({
  choice,
  choiceGroup,
  portionId,
  itemsInChoiceGroup,
  choiceIndex,
  choiceGroupIndex,
  updateChoice,
  isNestedChoice = false,
}) => {
  const { compactChoicesView, disableIncrementingForDefaults } = useConfig();
  const minChoices = choiceGroup.minimum;
  const maxChoices = choiceGroup.maximum;

  const choiceDisplayRecord = choice.displayRecordToUse;
  const quantity = choice.quantity ?? 0;
  const isSelected = quantity > 0;

  const totalSelectionQuantity = countChoiceGroupSelectionQuantities(
    choiceGroup.choices,
  );
  const choiceGroupMaxReached =
    maxChoices !== undefined && totalSelectionQuantity >= maxChoices;

  const portionIdToUse: number = getChildPortionId(
    choiceGroup.portionRatios,
    choice.choiceId ?? choice.productId,
    portionId,
  );

  const productToUse = choice.productToUse;
  const choiceToUse = choice.choiceId ? choice.choiceGroup : undefined;

  if (!productToUse && !choiceToUse) {
    return null;
  }

  const portion = productToUse
    ? productToUse.portions[portionIdToUse]
    : undefined;

  const priceToDisplay = portion
    ? getPriceForSupplement(
        choice,
        choiceGroup,
        portion,
        totalSelectionQuantity,
      )
    : 0;

  const hasValidPrice = (): boolean => {
    if (choice.productId) {
      const isDefault = choiceGroup.defaults.includes(choice.productId);
      // if a default or if inclusive range is same as max limit
      // these products will be charged at a supplement price
      if (
        isDefault ||
        (choiceGroup.inclusive &&
          choiceGroup.inclusive > 0 &&
          choiceGroup.inclusive === choiceGroup.maximum)
      ) {
        return true;
      }

      // else check that the portion has a price
      return portion ? doesPortionHaveValidPrice(portion) : false;
    }
    return true;
  };

  const choiceGroupId = choiceGroup.id;

  // // get maximum we can select for this item without pushing the choicegroup over the limit
  const sumOfOtherSelectionQuantities = totalSelectionQuantity - quantity;
  const maxForSelection = choiceGroup.maximum
    ? choiceGroup.maximum - sumOfOtherSelectionQuantities
    : choiceGroup.maximum;

  // // selection should be disabled if it isn't already selected and if selecting it would push
  // // the choice group past its maximum, (and is multiple choice)

  const calculateDisabled = (): boolean => {
    return !isSelected && choiceGroupMaxReached && choiceGroup?.maximum > 1;
  };

  const calculateOutOfStock = () => {
    if (productToUse) {
      const { portions } = productToUse;

      // If it hasn't been disabled yet, check if the portion exists. if it does not then disable it.
      return (
        portions[portionIdToUse] === undefined ||
        Boolean(productToUse.isOutOfStock) ||
        !hasValidPrice()
      );
    }
    return false;
  };

  // if portion has choices, we know to show a button.
  const calculateHasChoices = (): boolean => {
    if (productToUse) {
      const { portions } = productToUse;
      // if portion in the menu has choice groups, and those choice groups aren't empty, then we have choices
      return portions[portionIdToUse]?.choices?.length > 0;
    }
    // if choice doesn't have a product ID then it's a sub-choice group so of course...
    return true;
  };
  // goto logic:
  // button always "goes to", don't need to worry about this here.
  // checkbox "goes to" only if unchecked (not selected), and if choices are to be made..
  const hasChoices = calculateHasChoices();
  const hasNestedChoice = hasChoices && !isSelected;
  const disabled = calculateDisabled();
  const outOfStock = calculateOutOfStock();

  // This will be fixed in a later release
  const isUnsupportedNestedChoice = hasNestedChoice && isNestedChoice;

  /*
   * customiser needs to be displayed...
   * - only if item is selected
   * - and further customisation is needed (quantity or choices)
   */

  const hasCustomiser = () => {
    if (outOfStock) {
      return false;
    }

    if (
      disableIncrementingForDefaults &&
      choiceGroup.defaults.indexOf(choice.productId) !== -1
    ) {
      return false;
    }

    return (
      isSelected &&
      (hasChoices || getChoiceGroupType(minChoices, maxChoices) === 'Multiple')
    );
  };

  const customiserJsx = hasCustomiser() ? (
    <MenuChoiceCustomiser
      choiceGroupType={getChoiceGroupType(minChoices, maxChoices)}
      isSelected={isSelected}
      maxForSelection={maxForSelection}
      choiceIndex={choiceIndex}
      choiceGroupIndex={choiceGroupIndex}
      portionRatios={choiceGroup.portionRatios}
      updateChoice={updateChoice}
      quantity={quantity}
      hasChoices={hasChoices}
      disabled={disabled || isUnsupportedNestedChoice}
      portionId={portionId}
    />
  ) : null;

  return (
    <div
      className="menu-item choice-row"
      data-testid={`choice-option-${choiceGroup.id}`}
    >
      <div className="choice-section flex-grow">
        <MenuChoiceSelector
          choiceGroupType={getChoiceGroupType(minChoices, maxChoices)}
          isSelected={isSelected}
          choiceGroupId={choiceGroupId}
          choiceIndex={choiceIndex}
          choiceGroupIndex={choiceGroupIndex}
          disabled={disabled || isUnsupportedNestedChoice}
          outOfStock={outOfStock}
          numberOfChoices={itemsInChoiceGroup}
          updateChoice={updateChoice}
          hasNestedChoice={hasNestedChoice}
          portionId={portionId}
          data-testid="menu-item list-group-item-selector"
        />

        <div className="item-details">
          <span className={outOfStock ? 'item-name out-of-stock' : 'item-name'}>
            {choiceDisplayRecord?.name}
          </span>
          <CalorieAmountDisplay
            displayRecord={choiceDisplayRecord as DisplayRecord}
            portions={portion ? [portion] : []}
          />
          {choiceDisplayRecord?.description ? (
            <MenuItemDescription
              description={choiceDisplayRecord?.description}
              isOutOfStock={outOfStock}
            />
          ) : null}
        </div>
      </div>

      <div className="choice-section item-price-selector">
        {priceToDisplay !== 0 && !outOfStock ? (
          <ChoicePriceLabel priceToDisplay={priceToDisplay} />
        ) : null}
        {!compactChoicesView ? customiserJsx : null}
      </div>
    </div>
  );
};
