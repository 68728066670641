import { MdErrorOutline } from 'react-icons/md';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useCheckoutDetails } from 'contexts/CheckoutContext';

export const TipConflictText: React.FC = () => {
  const { basketTotal } = useCheckoutDetails();

  return basketTotal === 0 ? (
    <div className="reward-conflict text-muted" data-testid="tip-conflict-text">
      <MdErrorOutline size={25} />
      Tips cannot be applied because the total is{' '}
      <FormattedCurrency value={0} />.
    </div>
  ) : null;
};
