import { useConfig } from 'contexts/ConfigContext';
import { Link } from 'react-router-dom';

export const Logo: React.FC = () => {
  const { logoLinkToHomepage, homepage } = useConfig();
  return (
    <div className="logo">
      {logoLinkToHomepage ? (
        <div>
          <a href={`${homepage}`} target="blank">
            <div
              className="logo-img"
              data-testid="header-logo"
              role="img"
              aria-label="Company logo"
            />
          </a>
        </div>
      ) : (
        <Link to="/">
          <div
            className="logo-img"
            data-testid="header-logo"
            role="img"
            aria-label="Company logo"
          />
        </Link>
      )}
    </div>
  );
};
