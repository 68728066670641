import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import { TablePage } from 'PayMyBill/table';
import { PaymentRouter } from 'PayMyBill/routers';

export const TableRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={TablePage} />
    <Route
      path={`${match.path}/:tableId(\\d+)/account/:accountId(\\d+)`}
      component={PaymentRouter}
    />
    <Route
      path={`${match.path}/account/:accountId(\\d+)`}
      component={PaymentRouter}
    />
    <Route path={`${match.path}/:tableId(\\d+)`} component={TablePage} />
    <Route render={() => <Redirect to={`${match.url}`} />} />
  </Switch>
);
