import { useEffect } from 'react';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { VenuePage } from 'venue/VenuePage';
import { CheckoutRoutes } from 'routers/Checkout';
import { MenuPage } from 'menu';
import { SalesAreaPage } from 'sales-areas';
import { SentryLog } from 'components/Sentry';
import { CookieNoticePage } from 'legal/CookieNoticePage';
import { VenueHomePage } from 'venue/VenueHomePage';
import { NotFound } from 'routers/NotFound';
import { OrderingRoute } from 'routers/OrderingRoute';

/**
 * Backwards compatible router to make sure existing functionality remains
 */
export const LegacyRouter: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Preserve hyperlink functionality in menus by not auto scrolling those pages
    if (pathname.indexOf('menus') === -1) {
      window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/venues" />} />
      <OrderingRoute path="/venues" component={VenuePage} />
      <Route
        exact
        path="/venue"
        render={() => <Redirect to="/venues/list" />}
      />
      <OrderingRoute
        path="/venue/:venueId/:serviceName/sales-areas"
        component={SalesAreaPage}
      />
      <OrderingRoute
        path="/venue/:venueId/:serviceName/menus"
        component={MenuPage}
      />
      <Route path="/checkout" component={CheckoutRoutes} />
      <Route path="/complete" component={CheckoutRoutes} />
      <Route path="/sentry-testing" component={SentryLog} />
      <Route path="/cookie-notice" component={CookieNoticePage} />

      {/* Deeplinks */}
      {/* Order and pay */}
      <OrderingRoute
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId/table/:tableNumber"
        component={SalesAreaPage}
      />
      {/* CnC D2L */}
      <OrderingRoute
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId/time/:timeslot"
        component={SalesAreaPage}
      />
      {/* Push to table/timeslot selector */}
      <OrderingRoute
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId"
        component={SalesAreaPage}
      />
      <OrderingRoute
        exact
        path="/venue/:venueId/service/:serviceId/sales-areas"
        component={SalesAreaPage}
      />
      {/* Stupid redirects to handle the communication issue around what these URLs should be */}
      <Route
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId/table/:tableNumber/list"
        render={(props) => (
          <Redirect
            to={`/venue/${props.match.params.venueId}/sales-area/${props.match.params.salesAreaId}/service/${props.match.params.serviceId}/table/${props.match.params.tableNumber}`}
          />
        )}
      />
      {/* CnC D2L */}
      <Route
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId/time/:timeslot/list"
        render={(props) => (
          <Redirect
            to={`/venue/${props.match.params.venueId}/sales-area/${props.match.params.salesAreaId}/service/${props.match.params.serviceId}/time/${props.match.params.timeslot}`}
          />
        )}
      />
      {/* Push to table/timeslot selector */}
      <Route
        exact
        path="/venue/:venueId/sales-area/:salesAreaId/service/:serviceId/list"
        render={(props) => (
          <Redirect
            to={`/venue/${props.match.params.venueId}/sales-area/${props.match.params.salesAreaId}/service/${props.match.params.serviceId}`}
          />
        )}
      />
      <Route
        exact
        path="/venue/:venueId/list"
        render={(props) => (
          <Redirect to={`/venue/${props.match.params.venueId}`} />
        )}
      />
      <OrderingRoute exact path="/venue/:venueId" component={VenueHomePage} />
      <Route path="/venue/*" component={NotFound} />
    </Switch>
  );
};
