import { Panel } from 'react-bootstrap';
import { DefaultPageLayout } from 'PayMyBill/core/layouts';

interface FormLayoutProps {
  children: React.ReactNode;
  title: string;
}

export const FormLayout: React.FC<FormLayoutProps> = ({ children, title }) => (
  <DefaultPageLayout>
    <Panel>
      <Panel.Body>
        <h2 className="text-center">{title}</h2>
      </Panel.Body>
      {children}
    </Panel>
  </DefaultPageLayout>
);
