import cs from 'classnames';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import { Field } from 'types/models/Forms';
import {
  isNotOnlyWhitespace,
  isNumber,
  isRequired,
  maxValue,
  minLength,
  minValue,
} from 'core/components/RHF/validation';
import CurrencyInput from 'react-currency-input-field';
import { IntlShape, useIntl } from 'react-intl';
import { useConfig } from 'contexts/ConfigContext';
import { getCurrencySymbol } from 'utils';

interface CurrencyProps {
  field: Field;
  autocomplete?: string;
}

export const Currency: React.FC<CurrencyProps> = ({ field, autocomplete }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const { min, max, required, label, placeholder, name } = field;
  const labelToUse = label ? label : name;
  const hasError = errors[name] !== undefined;
  const fieldError = errors[name];
  const requiredLabel = required ? <span className="required">*</span> : null;
  const formGroupClasses = cs('form-group', hasError && 'has-error');
  const intl: IntlShape = useIntl();

  const { currency, currencySymbolsDisabled } = useConfig();

  const registerOptions: RegisterOptions = {
    ...isRequired(
      'userFieldType' in field ? field.label : labelToUse,
      required,
    ),
    ...minLength(labelToUse, min ? min : 0),
    ...maxValue(
      labelToUse,
      max,
      !currencySymbolsDisabled ? getCurrencySymbol(currency) : undefined,
    ),
    ...minValue(
      labelToUse,
      min,
      !currencySymbolsDisabled ? getCurrencySymbol(currency) : undefined,
    ),
    setValueAs: (value) => value.replace(/[^\d.]/g, ''),
    validate: {
      number: (value) => isNumber(value, labelToUse),
      ...(required && {
        whiteSpace: (value) => isNotOnlyWhitespace(value, labelToUse),
      }),
    },
  };

  return (
    <div className={formGroupClasses}>
      <label className="control-label" htmlFor={name}>
        {label}
        {requiredLabel}
      </label>
      <CurrencyInput
        className="form-control"
        type="text"
        placeholder={placeholder ? placeholder : labelToUse}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={hasError ? 'true' : 'false'}
        {...register(name, registerOptions)}
        autoComplete={autocomplete}
        allowNegativeValue={false}
        intlConfig={
          currencySymbolsDisabled
            ? undefined
            : { locale: intl.locale, currency: currency }
        }
        decimalsLimit={2}
        decimalScale={2}
        inputMode="numeric"
        defaultValue={field.content}
        data-testid="tip-currency-input"
      />
      {fieldError && (
        <span className="help-block" role="alert">
          {fieldError.message}
        </span>
      )}
    </div>
  );
};
