import { useEffect } from 'react';

import { Redirect, Link } from 'react-router-dom';
import { Alert, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { DefaultLayout } from 'PayMyBill/core/layouts';
import { LoadingOrRender } from 'PayMyBill/core/components';
import { TableList } from 'PayMyBill/table/components';
import { match, useHistory, useRouteMatch } from 'react-router';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { getTableText } from 'PayMyBill/utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAccountLoading,
  selectHasSearched,
  selectAccounts,
} from 'PayMyBill/table/selectors';
import {
  searchOpenAccountsByTableNumber,
  resetHasSearched,
} from 'PayMyBill/table/actions';
import { selectAccountError } from 'PayMyBill/table/reducer';
import { fetchBodyFormatter, setupFetchParams } from 'utils';
import { VenueSalesArea, ServiceType } from 'types/models';
import { History } from 'history';
import { resetBill } from 'PayMyBill/bill/actions';
import { RegisterOptions, useForm, SubmitHandler } from 'react-hook-form';

interface ParamsProps {
  venueId: string;
  areaId: string;
  tableId: string;
}

interface FormProps {
  tableNumber: number;
}

export const TablePage: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<ParamsProps>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>();

  const dispatch = useDispatch();

  const {
    pmb: {
      tableNumber: { dictionary, settings },
    },
  } = useConfig();

  const {
    payMyBillSelectorText,
    table,
    retrieveMyBillButtonText,
    payMyBillEnterTablePlaceholder,
  } = usePhrases();

  useEffect(() => {
    const fetchAccounts = async (
      history: History<unknown> | string[],
      match: match<ParamsProps>,
    ) => {
      const fetchParams = setupFetchParams();
      const requestBody = {
        method: 'venues',
        siteId: Number(match.params.venueId),
      };
      const res = await fetch(fetchParams.url, {
        method: 'POST',
        headers: {
          ...fetchParams.headers,
        },
        body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
      });

      const json = await (res.ok && res.json());
      if (!json.venues || !json.venues.length) {
        history.push('/pay-my-bill/venues');
      } else if (match.params.areaId) {
        const found = json.venues[0].salesArea.some(
          (area: VenueSalesArea) =>
            area.id === Number(match.params.areaId) &&
            area.services.includes(ServiceType.PayMyBill) &&
            Boolean(area.canOrder),
        );
        if (!found) {
          history.push('/pay-my-bill/venues');
        }
      }
      if (match.params.tableId) {
        dispatch(
          searchOpenAccountsByTableNumber(
            match.params.venueId,
            match.params.areaId,
            match.params.tableId,
          ),
        );
      }
    };

    fetchAccounts(history, match);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useSelector(selectAccountLoading);
  const hasSearched = useSelector(selectHasSearched);

  const accounts = useSelector(selectAccounts);

  const errorMessage = useSelector(selectAccountError);

  const onSubmit: SubmitHandler<FormProps> = (values) => {
    dispatch(resetBill());
    dispatch(
      searchOpenAccountsByTableNumber(
        match.params.venueId,
        match.params.areaId,
        values.tableNumber,
      ),
    );
  };

  const handleResetHasSearched = () => {
    dispatch(resetHasSearched());
  };

  const validationRules: RegisterOptions = {
    required: {
      value: true,
      message: 'Table number is required.',
    },
    min: {
      value: 1,
      message: 'Table number must be greater than 0.',
    },
    max: {
      value: 999,
      message: 'Table number cannot be larger than 999.',
    },
    pattern: {
      value: new RegExp('^[0-9]*$'),
      message: 'Table number must be a number',
    },
  };

  return (
    <DefaultLayout title={getTableText(payMyBillSelectorText, table)}>
      <LoadingOrRender isLoading={loading}>
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={`form-group ${errors.tableNumber ? 'has-error' : ''}`}
            >
              <input
                id="search-table"
                data-testid="pay-my-bill-text-search-table"
                {...register('tableNumber', validationRules)}
                placeholder={getTableText(
                  payMyBillEnterTablePlaceholder,
                  table,
                )}
                className="form-control"
                aria-invalid={errors.tableNumber ? 'true' : 'false'}
                aria-required="true"
                aria-label="Table number"
              />
              {errors.tableNumber && (
                <span className="help-block" role="alert">
                  {errors.tableNumber?.message}
                </span>
              )}
            </div>
            <Row>
              <Col xs={5}>
                <Link
                  id="button-cancel"
                  to={'/pay-my-bill'}
                  className="btn btn-default"
                  onClick={handleResetHasSearched}
                >
                  {dictionary?.buttons?.cancel || ''}
                </Link>
              </Col>
              <Col xs={7}>
                <ButtonToolbar bsClass="text-right">
                  <Button
                    id="button-confirm"
                    data-testid="pay-my-bill-button-retrieve-bill"
                    type="submit"
                    bsClass="btn btn-primary"
                  >
                    {retrieveMyBillButtonText}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </form>

          <br />
          {hasSearched &&
            !loading &&
            (errorMessage ? (
              <Alert bsStyle="danger">
                {`There has been a problem getting your bill. Please try again.`}
              </Alert>
            ) : accounts?.length < 1 ? (
              <Alert bsStyle="danger" data-testid="no-open-account-error">
                {dictionary?.errors?.noAccounts}
              </Alert>
            ) : accounts?.length === 1 ? (
              <Redirect
                to={`${match.url}/account/${accounts[0].AccountId}`}
                push
              />
            ) : settings.multipleAccounts ? (
              <TableList accounts={accounts} path={`${match.url}/account`} />
            ) : (
              <Alert bsStyle="danger">
                <h4>{dictionary?.errors?.multipleAccounts?.title}</h4>
                <small>{dictionary?.errors?.multipleAccounts?.body}</small>
              </Alert>
            ))}
        </>
      </LoadingOrRender>
    </DefaultLayout>
  );
};
