import { initialState } from 'PayMyBill/venues/model';
import {
  SET_FILTER,
  SET_SERVICE_ID,
  SET_RESULT_IDS,
  FETCH_REQUESTED,
  FETCH_COMPLETE,
  FETCH_ERROR,
  MERGE_VENUES,
  MERGE_SERVICES,
} from 'PayMyBill/venues/actionTypes';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filterTerm: action.filterTerm,
      };

    case SET_SERVICE_ID:
      return {
        ...state,
        serviceID: action.serviceID,
      };

    case SET_RESULT_IDS:
      return {
        ...state,
        results: action.results,
      };

    case FETCH_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          error: false,
          isFetching: true,
          payload: null,
        },
      };

    case FETCH_COMPLETE:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          isFetching: false,
          payload: action.payload,
        },
      };

    case FETCH_ERROR:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          isFetching: false,
          error: true,
          payload: action.payload,
        },
      };

    case MERGE_VENUES:
      return {
        ...state,
        entities: {
          ...state.entities,
          venues: state.entities.venues.merge(action.venues),
        },
      };

    case MERGE_SERVICES:
      return {
        ...state,
        entities: {
          ...state.entities,
          services: state.entities.services.merge(action.services),
        },
      };

    default:
      return state;
  }
};
