import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { MenuImageCard } from 'menu/components/MenuDisplayItems';
import { useMenuGroups, useMenus } from 'contexts/MenuContext';
import { Loader } from 'components/Loader';
import { useVenues } from 'contexts/VenueContext';
import { useConfig } from 'contexts/ConfigContext';
import cs from 'classnames';
import { sortWithSortOrder } from 'utils';

interface ParamsProps {
  serviceName: string;
}

export const MenuGridItems: React.FC = () => {
  const { menus, loadingMenus, getMenus, layoutType } = useMenus();
  const { getMenuPages } = useMenuGroups();
  const { selectedVenue } = useVenues();
  const history = useHistory();
  const params = useParams<ParamsProps>();
  const { wlaAspectRatioEnabled, multipleMenusEnabled } = useConfig();

  const selectMenu = (id: number) => {
    getMenuPages(id);
    history.push(
      `/venue/${selectedVenue?.id}/${params.serviceName}/menus/${id}/`,
    );
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getMenus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuLayoutType = layoutType || 'banner';

  const menuGridClasses = cs(
    'menu-grid',
    `menu-grid-${menuLayoutType}`,
    wlaAspectRatioEnabled &&
      menuLayoutType === 'banner' &&
      'menu-grid-banner-wla',
  );

  const sortedMenus = menus?.toSorted((a, b) => sortWithSortOrder(a, b));

  if (!multipleMenusEnabled && !loadingMenus) {
    if (menus.length > 0) {
      selectMenu(sortedMenus[0]?.id);
    } else {
      return (
        <h4 className="text-center">
          There are currently no available menus at this venue.
        </h4>
      );
    }
  }

  if (loadingMenus) {
    return <Loader text="Loading Menus" />;
  }

  return multipleMenusEnabled ? (
    <>
      <div className={menuGridClasses} data-testid="menu-grid">
        {sortedMenus.map((menu, index) => (
          <MenuImageCard
            key={`menu-${menu.id}`}
            menu={menu}
            onClick={selectMenu}
            menuLayoutType={menuLayoutType}
            index={index}
          />
        ))}
      </div>
      {!menus.length ? (
        <h4 className="text-center">
          There are currently no available menus at this venue.
        </h4>
      ) : null}
    </>
  ) : null;
};
