import localForage from 'localforage';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form/immutable';
import { persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';

import checkout from './checkout';
import core from './core';

import { createPayMyBillReducer } from 'PayMyBill/reducer';
import sso from './sso';
import user from './user';
import venue from './venue';
import orderHistory from 'order-history';

const rootPersistConfig = {
  key: 'root',
  storage: localForage,
  whitelist: ['user'],
  transforms: [immutableTransform()],
};

const createRootReducer = () =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      payMyBill: createPayMyBillReducer,
      checkout: checkout.reducer,
      core: core.reducer,
      user: user.reducer,
      venue: venue.reducer,
      form: formReducer,
      sso: sso.reducer,
      orderHistory: orderHistory.reducer,
    }),
  );

export default createRootReducer;
