import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field } from 'redux-form/immutable';
import { Field as FieldType } from 'types/models/Forms';
import { FieldGroup } from '../FormComponents';
import {
  min,
  max,
  required,
  mobile,
  yearLow,
  yearMax,
  dob,
  names,
  loyaltyCard,
} from 'core/validation';

interface TextProps {
  field: FieldType;
}

/**
 * @deprecated
 */
export const Text: React.FC<TextProps> = ({ field }) => {
  const [validation, setValidation] = useState<
    ((value: string) => string | undefined)[]
  >([]);

  useEffect(() => {
    const fieldMin = min(field.min);
    const fieldMax = max(field.max);
    const fieldIsMobile = mobile(field.isMobile ?? false);
    const fieldDOB = dob(field.isDob ?? false);
    const fieldYearMin = yearLow(field.isDob ?? false);
    const fieldYearMax = yearMax(field.isDob ?? false);
    const fieldNames = names(field.isName ?? false);
    const fieldLoyaltyCard = loyaltyCard(field.isLoyaltyCard ?? false);
    const fieldRequired = required(field.required);

    const tmpValidation = [
      fieldMin,
      fieldMax,
      fieldIsMobile,
      fieldDOB,
      fieldYearMin,
      fieldYearMax,
      fieldNames,
      fieldLoyaltyCard,
      fieldRequired,
    ];

    setValidation(tmpValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width || 12}>
      <Field
        name={field.name}
        component={FieldGroup}
        type="text"
        placeholder={field.placeholder}
        label={field.label}
        validate={validation}
      />
    </Col>
  );
};
