import { PayMyBillConfig } from 'types/models';

export const defaultPmbConfig: PayMyBillConfig = {
  addTip: {
    dictionary: {
      buttons: { cancel: 'Cancel', confirm: 'Add' },
      errors: {},
      title: 'Add a Tip',
    },
    settings: { presets: [] },
  },
  billReview: {
    dictionary: {
      buttons: {
        cancel: '< Back',
        confirm: 'Pay ',
        addTip: 'Add A Tip',
        modifyTip: 'Change Tip',
      },
      errors: { accountPaid: 'This account has been paid' },
      title: 'Review Your Bill',
    },
    settings: { enableTip: false },
  },
  findVenue: {
    dictionary: { buttons: {}, errors: {}, title: 'Select a Venue' },
    settings: { bannerImage: '', headerCopy: '' },
  },
  guestCheckout: {
    dictionary: {
      buttons: { cancel: '< Back', confirm: 'Continue' },
      errors: {},
      title: 'Guest Checkout',
    },
  },
  pay: {
    dictionary: {
      buttons: { cancel: '< Back', confirm: 'Pay' },
      errors: {},
      title: 'Make Payment',
    },
  },
  tableNumber: {
    dictionary: {
      buttons: { cancel: '< Back', confirm: 'Retrieve Bill' },
      errors: {
        noAccounts: 'No Open Account Found',
        notFound: '',
        multipleAccounts: {
          body: 'Please contact a member of staff to pay',
          title: 'Multiple bills found for this table',
        },
      },
      title: 'Find your Table',
    },
    settings: { bannerImage: '', headerCopy: '', multipleAccounts: false },
  },
};
