import { useEffect } from 'react';

import { Grid } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import Edit from 'user/components/LoggedInUserComponents/Edit';
import { Order } from 'order-history/components/Order';
import PasswordChange from 'user/components/LoggedInUserComponents/PasswordChange';
import { NotFound } from 'routers';
import {
  AuthenticatedOrderingRoute,
  AuthenticatedRoute,
} from 'routers/PrivateRoute';
import { useDispatch } from 'react-redux';
import { removeNotification } from 'core/actions';
import { OrderHistoryPage } from 'order-history/OrderHistoryPage';
import { AccountDeletion } from 'user/components/LoggedInUserComponents';

export const LoggedInUserRouter: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <Switch>
        <AuthenticatedRoute
          path="/user/delete-account"
          component={AccountDeletion}
        />
        <AuthenticatedOrderingRoute
          path="/user/order/:orderId"
          component={Order}
        />
        <AuthenticatedOrderingRoute
          path="/user/order"
          component={OrderHistoryPage}
        />
        <AuthenticatedOrderingRoute path="/user/edit" component={Edit} />
        <AuthenticatedOrderingRoute
          path="/user/password-change"
          component={PasswordChange}
        />

        <Redirect exact from="/user" to="/user/order" />
        <Route component={NotFound} />
      </Switch>
    </Grid>
  );
};
