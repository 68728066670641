import { useEffect } from 'react';
import {
  FormGroup,
  FormControl,
  Panel,
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import { Loader } from 'components/Loader';
import { VenuesRouter } from 'PayMyBill/routers';
import { ListLayout } from 'PayMyBill/core/layouts';
import { useConfig } from 'contexts/ConfigContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchVenuesByLocation,
  fetchVenuesIfNeeded,
  setFilter,
} from 'PayMyBill/venues/actions';
import {
  selectFilterTerm,
  selectIsVenuesLoading,
} from 'PayMyBill/venues/selectors';
import { Notification } from 'core/components/Notification';
import { FaLocationArrow } from 'react-icons/fa';

export const VenuesPage: React.FC = () => {
  const {
    pmb: {
      findVenue: { dictionary },
    },
  } = useConfig();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVenuesIfNeeded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const applyFilter = (e: React.ChangeEvent<FormControl>) => {
    dispatch(setFilter((e.target as unknown as HTMLInputElement).value));
  };

  const handleLocationRequest = () => dispatch(fetchVenuesByLocation());
  const hasGeolocation: boolean = 'geolocation' in navigator;
  const loading = useSelector(selectIsVenuesLoading);
  const filterTerm = useSelector(selectFilterTerm);

  return (
    <>
      <Notification />
      <ListLayout>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Panel
              data-testid="pay-my-bill-venue-selection"
              className="header-panel"
            >
              <Panel.Body>
                <Row>
                  <Col xs={12} sm={6}>
                    <h1>{dictionary?.title}</h1>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className="text-right">
                      {hasGeolocation && (
                        <Button
                          bsStyle="link"
                          bsSize="xsmall"
                          className="pull-right"
                          onClick={handleLocationRequest}
                          type="button"
                        >
                          <span className="h4 pull-right btn-use-location btn-with-icon">
                            <FaLocationArrow /> {'Use your current location'}
                          </span>
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                <FormGroup>
                  <FormControl
                    id="search-venues"
                    type="input"
                    onChange={applyFilter}
                    value={filterTerm || ''}
                    placeholder="Search"
                  />
                </FormGroup>
              </Panel.Body>
            </Panel>
            <VenuesRouter />
          </>
        )}
      </ListLayout>
    </>
  );
};
