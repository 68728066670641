import { combineReducers } from 'redux';

import { reducer as VenuesReducer } from 'PayMyBill/venues/reducer';
import { reducer as TableReducer } from 'PayMyBill/table/reducer';
import { reducer as BillReducer } from 'PayMyBill/bill/reducer';
import { reducer as CheckoutReducer } from 'PayMyBill/checkout/reducer';

export const createPayMyBillReducer = combineReducers({
  venues: VenuesReducer,
  table: TableReducer,
  bill: BillReducer,
  checkout: CheckoutReducer,
});
