import { Grid } from 'react-bootstrap';
import { Header } from 'PayMyBill/core/components';

interface DefaultPageLayoutProps {
  children: React.ReactNode;
}

export const DefaultPageLayout: React.FC<DefaultPageLayoutProps> = ({
  children,
}) => {
  return (
    <div>
      <div id="mainContainer">
        <Header />
        <div id="routerContainer" className="pmb-container">
          <Grid>{children}</Grid>
        </div>
      </div>
    </div>
  );
};
