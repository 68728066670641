import { useEffect } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import { Alert, ButtonToolbar, Row, Col, Panel } from 'react-bootstrap';
import { BasicBill } from 'PayMyBill/bill/components/billDisplay';
import { DefaultLayout, DefaultPageLayout } from 'PayMyBill/core/layouts';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'components/Loader';
import {
  selectBill,
  selectHasTip,
  selectIsBillLoading,
  selectIsPayable,
  selectTableNumber,
  selectTipAmount,
} from 'PayMyBill/bill/selectors';
import { fetchBillIfNeeded } from 'PayMyBill/bill/actions';
import { resetHasSearched } from 'PayMyBill/table/actions';

interface ParamsProps {
  venueId: string;
  areaId: string;
  accountId: string;
}

export const ReviewBillPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    pmb: {
      billReview: { dictionary },
    },
  } = useConfig();

  useEffect(() => {
    dispatch(resetHasSearched());
    dispatch(fetchBillIfNeeded(venueId, areaId, accountId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { table } = usePhrases();
  const tableNumber = useSelector(selectTableNumber);
  const hasTip = useSelector(selectHasTip);
  const loading = useSelector(selectIsBillLoading);
  const isPayable = useSelector(selectIsPayable);
  const tipAmount = useSelector(selectTipAmount);
  const bill = useSelector(selectBill);

  const {
    params: { venueId, areaId, accountId },
  } = useRouteMatch<ParamsProps>();

  if (loading) {
    return (
      <DefaultLayout>
        <Loader />
      </DefaultLayout>
    );
  }

  return (
    <DefaultPageLayout>
      <Panel data-testid="bill">
        <Panel.Body>
          <h2 className="review-bill-title">{`${table} ${tableNumber}`}</h2>
        </Panel.Body>
      </Panel>
      <Panel>
        {bill && (
          <BasicBill bill={bill} hasTip={hasTip} tipAmount={tipAmount} />
        )}
      </Panel>
      {isPayable ? (
        <Panel className="panel-sticky-bottom">
          <Panel.Body>
            <Row>
              <Col xs={6}>
                <Link
                  id="button-cancel"
                  to={`/pay-my-bill/venue/${venueId}/${areaId}`}
                  className="btn btn-default"
                >
                  {dictionary?.buttons?.cancel}
                </Link>
              </Col>
              <Col xs={6}>
                <ButtonToolbar bsClass="text-right">
                  <Link
                    id="button-confirm"
                    data-testid="pay-my-bill-button-pay-bill"
                    className="btn btn-primary"
                    to={`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}/guest-checkout`}
                  >
                    {`${dictionary?.buttons?.confirm}`}
                    <FormattedCurrency
                      value={
                        Number(bill.outstandingBalanceToPay) + Number(tipAmount)
                      }
                    />
                  </Link>
                </ButtonToolbar>
              </Col>
            </Row>
          </Panel.Body>
        </Panel>
      ) : (
        <Alert bsStyle="success">{dictionary?.errors?.accountPaid}</Alert>
      )}
    </DefaultPageLayout>
  );
};
