export const initialState = {
  fetch: {
    loading: false,
    response: null,
  },
  data: null,
  payments: null,
  tableNumber: '',
  tipAmount: null,
};

export const formatBill = (bill) => {
  const payments = bill.account.account.filter(
    (payment) => payment && payment.paymentMethodId,
  );

  return {
    accountTotal: bill.account.accountTotal,
    outstandingBalanceToPay: bill.account.outstandingBalanceToPay,
    courses: bill.account.courses
      // Dedupe courses
      .reduce(
        (a, v) => (a.some((item) => item.id === v.id) ? a : [...a, v]),
        [],
      )
      // Sort by course ID ASC
      .sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0))
      // add items to course array
      .map((course) => ({
        id: course.id,
        name: course.name,
        // filter account to items that include matching course ID
        items: bill.account.account.filter(
          (item) => item.courseId === course.id,
        ),
      })),
    promotions: {
      items: bill.account.account
        .filter((item) => 'promotionId' in item || 'discountId' in item)
        .map((promotion) => ({
          total: promotion.amount,
          display: {
            name: promotion.name,
          },
        })),
    },
    taxes: bill.account.taxes,
    payments: {
      total: Number(payments.reduce((a, v) => a + v.amount, 0).toFixed(2)),
      items: payments.map((payment) => ({
        paymentMethodId: payment.paymentMethodId,
        total: payment.amount,
        tax: payment.inclusiveTax,
        tip: payment.tip,
        display: {
          name: payment.name,
        },
      })),
    },
  };
};
