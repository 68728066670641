import { useEffect } from 'react';

import { useConfig, usePhrases } from 'contexts/ConfigContext';
import { DefaultLayout } from 'PayMyBill/core/layouts';
import { useDispatch } from 'react-redux';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { redirectToBillReviewIfNeeded } from 'PayMyBill/bill/actions';
import { setGuestEmailAddress } from 'PayMyBill/checkout/actions';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { Email } from 'core/components/RHF/FieldTemplates';
import { Field } from 'types/models/Forms';

interface ParamsProps {
  venueId: string;
  areaId: string;
  accountId: string;
}

interface FormProps {
  email: string;
}

export const GuestCheckoutPage: React.FC = () => {
  const {
    guestCheckout: { guestCheckoutTitle, guestCheckoutPhrase },
  } = usePhrases();

  const match = useRouteMatch<ParamsProps>();
  const {
    params: { venueId, areaId, accountId },
  } = match;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(redirectToBillReviewIfNeeded(history, match));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useForm<FormProps>();

  const {
    pmb: {
      guestCheckout: { dictionary },
    },
  } = useConfig();

  const onSubmit: SubmitHandler<FormProps> = (values) => {
    dispatch(setGuestEmailAddress(values.email));

    history.push(
      `/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}/pay`,
    );
  };

  const emailField: Field = {
    label: 'Email Address',
    max: 64,
    min: 5,
    name: 'email',
    placeholder: 'Email Address',
    required: true,
    type: 'email',
  };
  return (
    <DefaultLayout title={guestCheckoutTitle}>
      <FormProvider {...methods}>
        <form>
          <p>{guestCheckoutPhrase}</p>
          <Email field={emailField} />
          <Row>
            <Col xs={6}>
              <Link
                to={`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}`}
                className="btn btn-default"
                data-testid="pay-my-bill-button-back-emailid"
              >
                {dictionary?.buttons?.cancel}
              </Link>
            </Col>
            <Col xs={6}>
              <ButtonToolbar bsClass="text-right">
                <Button
                  type="submit"
                  bsClass="btn btn-primary"
                  data-testid="pay-my-bill-button-guest-emailid-submit"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  {dictionary?.buttons?.confirm}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </form>
      </FormProvider>
    </DefaultLayout>
  );
};
