import { useAPI } from 'api/useAPI';
import { requestBodyFormatter } from 'api/utils';
import axios, { AxiosRequestConfig } from 'axios';
import { addNotification } from 'core/actions';
import { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ErrorResponse } from 'types/models';
import {
  AccountDeletionChallenge,
  AccountDeletionResponse,
} from 'types/models/Responses/AccountDeletionResponse';
import { displayChallenge, hideOTPModal, logOut } from 'user/actions';

interface UserContext {
  initialised: boolean;
  deleteAccountRequest: () => void;
  confirmAccountDeletion: (otp: string) => void;
  resetDeletionStatus: () => void;
  accountDeleted: boolean;
}

export const UserContext = createContext<UserContext>({
  initialised: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  deleteAccountRequest: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  confirmAccountDeletion: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetDeletionStatus: () => {},
  accountDeleted: false,
});

export const useUser = (): UserContext => {
  const consumer = useContext(UserContext);
  if (!consumer.initialised) {
    throw new Error('User Context Provider not initialised');
  }
  return consumer;
};

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [challenge, setChallenge] = useState<AccountDeletionChallenge>();
  const [accountDeleted, setAccountDeleted] = useState<boolean>(false);
  const { url, deleteAccount, deleteAccountWithOtp } = useAPI();

  const resetDeletionStatus = () => {
    setAccountDeleted(false);
  };

  const deleteAccountRequest = () => {
    const deleteAccountConfig = deleteAccount();
    const requestOptions: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: deleteAccountConfig.headers,
      data: requestBodyFormatter({
        method: deleteAccountConfig.method,
        ...deleteAccountConfig.body,
      }),
    };

    axios(requestOptions)
      .then((response) => {
        const data = response.data as AccountDeletionResponse;
        if (data.challenge) {
          setChallenge(data.challenge);
          dispatch(
            displayChallenge(
              data.challenge.email,
              data.challenge.channel,
              response.data.challenge.channel === 'email'
                ? response.data.challenge.email
                : response.data.challenge.mobilePhoneEndsWith,
              data.challenge.expirationTimeUtc,
              data.challenge.id,
            ),
          );
        } else {
          const data = response.data as ErrorResponse;
          if (data.code === -959) {
            dispatch(logOut(true));
          }
          dispatch(addNotification(data.detail, 'danger', data.code));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('There was an error. Please try again.', 'danger'),
        );
      });
  };

  const confirmAccountDeletion = (otp: string) => {
    const deleteAccountWithOtpConfig = deleteAccountWithOtp();
    const deleteAccountConfirm: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      headers: deleteAccountWithOtpConfig.headers,
      data: requestBodyFormatter({
        method: deleteAccountWithOtpConfig.method,
        ...deleteAccountWithOtpConfig.body,
        user: {
          email: challenge?.email,
        },
        challengeID: challenge?.id,
        OTP: otp,
      }),
    };

    axios(deleteAccountConfirm)
      .then((response) => {
        if (response.data.response === 'OK') {
          setAccountDeleted(true);
          dispatch(hideOTPModal);
          setChallenge(undefined);
        } else {
          const data = response.data as ErrorResponse;
          if (data.code === -959) {
            dispatch(logOut(true));
          }
          dispatch(addNotification(data.detail, 'danger', data.code));
        }
      })
      .catch(() => {
        dispatch(
          addNotification('There was an error. Please try again.', 'danger'),
        );
      });
  };

  return (
    <UserContext.Provider
      value={{
        initialised: true,
        deleteAccountRequest,
        confirmAccountDeletion,
        accountDeleted,
        resetDeletionStatus,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
