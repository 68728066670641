import { fetchBodyFormatter, setupFetchParams } from 'utils';
import {
  SET_INITIALIZING,
  SET_INITIALIZED,
  SET_GUEST_EMAIL_ADDRESS,
  SET_PAYMENT_PROCESSING,
  SET_PAYMENT_FAILED,
  SET_PAYMENT_COMPLETE,
  SET_CAN_MAKE_PAYMENT,
  RESET_BRAINTREE,
} from './actionTypes';
import { receiveBill } from 'PayMyBill/bill/actions';
import { selectBillTotalToPay } from 'PayMyBill/checkout/selectors';

export const setGuestEmailAddress = (guestEmailAddress) => ({
  type: SET_GUEST_EMAIL_ADDRESS,
  guestEmailAddress,
});

const setInitializing = {
  type: SET_INITIALIZING,
};

const setInitialized = (token) => (dispatch) =>
  dispatch({
    type: SET_INITIALIZED,
    token,
  });

const fetchBraintreeClientToken = () => {
  const fetchParams = setupFetchParams();

  const requestBody = {
    method: 'getBraintreeToken',
  };

  return fetch(fetchParams.url, {
    method: 'POST',
    headers: {
      ...fetchParams.headers,
    },
    body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
  })
    .then((res) => res.ok && res.json())
    .then((json) => json.braintreeToken);
};

export const initializePaymentProcessor = async (dispatch) => {
  dispatch(setInitializing);
  dispatch(setCanMakePayment(false));

  const clientToken = await fetchBraintreeClientToken();

  dispatch(setInitialized(clientToken));
};

export const setPaymentProcessing = {
  type: SET_PAYMENT_PROCESSING,
};

export const setPaymentFailed = (errorMessage) => ({
  type: SET_PAYMENT_FAILED,
  errorMessage,
});

const setPaymentComplete = {
  type: SET_PAYMENT_COMPLETE,
};

export const setupPaymentRequest =
  (instance, siteId, salesAreaId, accountId, history) =>
  (dispatch, getState) => {
    const state = getState();
    instance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: selectBillTotalToPay(state),
          email: state.payMyBill.checkout.guestEmailAddress,
        },
      },
      (err, payload) => {
        if (err) {
          instance.clearSelectedPaymentMethod();
          dispatch(setPaymentFailed());
          console.error(err);
          return;
        }

        dispatch(
          submitPayment(payload, siteId, salesAreaId, accountId, history),
        );
      },
    );
  };

export const submitPayment =
  ({ deviceData, nonce, type }, siteId, salesAreaId, accountId, history) =>
  (dispatch, getState) => {
    dispatch(setPaymentProcessing);
    const state = getState();

    const fetchParams = setupFetchParams();

    const requestBody = {
      method: 'makeAccountPayment',
      amount: state.payMyBill.bill.data.outstandingBalanceToPay,
      siteId,
      salesAreaId,
      accountId,
      tip: state.payMyBill.bill.tipAmount || 0,
      customer: {
        email: state.payMyBill.checkout.guestEmailAddress,
      },
      authorisation: {
        deviceData,
        nonce,
        paymentMethod: type,
      },
    };

    fetch(fetchParams.url, {
      method: 'POST',
      headers: {
        ...fetchParams.headers,
      },
      body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
    })
      .then((res) => res.ok && res.json())
      .then((json) => {
        if (json.response === 'OK') {
          dispatch(setPaymentComplete);
          dispatch(receiveBill(json));

          history.push(
            `/pay-my-bill/venue/${siteId}/${salesAreaId}/account/${accountId}/complete`,
          );
        } else {
          dispatch(setPaymentFailed(json.detail));
        }
      });
  };

export const setCanMakePayment = (canMakePayment) => ({
  type: SET_CAN_MAKE_PAYMENT,
  canMakePayment,
});

export const resetPaymentError = () => ({
  type: RESET_BRAINTREE,
});
