import { AztecChoice } from 'types/models';
import { getSelectedChoices } from 'menu/utils';
import { BasketChoiceLine } from 'checkout/components/CheckoutBasket/BasketLines';
import { Fragment } from 'react';

interface BasketChoiceProps {
  choices: AztecChoice[];
  showNested?: boolean;
}

export const BasketChoices: React.FC<BasketChoiceProps> = ({
  choices,
  showNested = true,
}) => {
  return (
    <>
      {choices.map((choice, i) => {
        const selectedChoices = getSelectedChoices(choice.choiceGroup.choices);
        if (selectedChoices) {
          return (
            <div
              className="text-muted"
              key={`${choice.choiceId}-${choice.displayRecordId}-${i}`}
            >
              {selectedChoices.map((sc, index) => (
                <Fragment
                  key={`${sc.choiceId ?? sc.productId}-${
                    sc.displayRecordToUse?.id
                  }-${index}`}
                >
                  <BasketChoiceLine
                    choice={sc}
                    showNested={showNested}
                    key={`${sc.choiceId ?? sc.productId}-${
                      sc.displayRecordToUse?.id
                    }`}
                  />
                  {selectedChoices.length - index > 1 && ', '}
                </Fragment>
              ))}
            </div>
          );
        }
        return null;
      })}
    </>
  );
};
