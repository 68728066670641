const namespace = 'pmb/venues';

export const SET_FILTER = `${namespace}/set_filter`;
export const SET_SERVICE_ID = `${namespace}/set_target_service`;
export const SET_RESULT_IDS = `${namespace}/set_results`;
export const FETCH_REQUESTED = `${namespace}/fetch_requested`;
export const FETCH_COMPLETE = `${namespace}/fetch_complete`;
export const FETCH_ERROR = `${namespace}/fetch_error`;
export const MERGE_VENUES = `${namespace}/merge_venues`;
export const MERGE_SERVICES = `${namespace}/merge_services`;
