import { Grid } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { Checkout } from 'checkout/components/Checkout';
import { Complete } from 'complete/Complete';
import { NotFound } from 'routers';
import { AuthenticatedOrGuestOrderingRoute } from 'routers/PrivateRoute';

export const CheckoutRoutes: React.FC = () => {
  return (
    <Grid>
      <Switch>
        <AuthenticatedOrGuestOrderingRoute
          path="/checkout"
          component={Checkout}
        />
        <AuthenticatedOrGuestOrderingRoute
          path="/complete"
          component={Complete}
        />
        <Route component={NotFound} />
      </Switch>
    </Grid>
  );
};
