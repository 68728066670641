import { useEffect } from 'react';

import { match, useHistory, useRouteMatch } from 'react-router';
import { Loader } from 'components/Loader';
import { DefaultLayout } from 'PayMyBill/core/layouts';
import { VenueSalesArea, ServiceType } from 'types/models';
import { fetchBodyFormatter, setupFetchParams } from 'utils';
import { History } from 'history';

interface UrlParamProps {
  url: string;
  venueId: string;
}

export const SalesAreasPage: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<UrlParamProps>();

  const fetchSalesAreas = async (
    history: History<unknown> | string[],
    match: match<UrlParamProps>,
  ) => {
    const fetchParams = setupFetchParams();

    const requestBody = {
      method: 'venues',
      siteId: Number(match.params.venueId),
    };

    const res = await fetch(fetchParams.url, {
      method: 'POST',
      headers: {
        ...fetchParams.headers,
      },
      body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
    });

    const json = await (res.ok && res.json());
    if (
      json.venues &&
      json.venues.length > 0 &&
      json.venues[0]?.salesArea.length > 0
    ) {
      const validSalesAreas: VenueSalesArea[] =
        json.venues[0]?.salesArea.filter((sa: VenueSalesArea) => {
          return (
            Boolean(sa.canOrder) && sa.services.includes(ServiceType.PayMyBill)
          );
        });

      history.push(`${match.url}/${validSalesAreas[0]?.id}`);
    } else {
      history.push('/pay-my-bill/venues');
    }
  };

  useEffect(() => {
    fetchSalesAreas(history, match);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DefaultLayout>
      <Loader />
    </DefaultLayout>
  );
};
