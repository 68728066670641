import { useEffect } from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { ButtonToolbar, Row, Col } from 'react-bootstrap';
import { DefaultLayout } from 'PayMyBill/core/layouts/';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig } from 'contexts/ConfigContext';
import { PayButton } from 'PayMyBill/checkout/components/PayButton';
import { Loader } from 'components/Loader';
import BraintreeWebDropIn from 'braintree-web-drop-in';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import braintreeClient from 'braintree-web/client';
import {
  initializePaymentProcessor,
  resetPaymentError,
  setCanMakePayment,
  setPaymentFailed,
  setupPaymentRequest,
} from 'PayMyBill/checkout/actions';
import {
  selectBillTotalToPay,
  selectClientToken,
} from 'PayMyBill/checkout/selectors';
import { selectBraintree } from 'PayMyBill/checkout/reducer';
import { selectHasBill } from 'PayMyBill/bill/selectors';

let instance;
let wrapper;

export const PayPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializePaymentProcessor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    currency,
    googleMerchantId,
    pmb: {
      pay: { dictionary },
    },
    client: clientName,
    clientPaymentName,
  } = useConfig();

  const {
    params: { venueId, areaId, accountId },
  } = useRouteMatch();

  const history = useHistory()

  const amount = useSelector(selectBillTotalToPay);
  const braintree = useSelector(selectBraintree);
  const hasBill = useSelector(selectHasBill);
  const clientToken = useSelector(selectClientToken);

  // TODO - this will be fixed with the PMB refactor
  // const braintreeDisplayName = useSelector(selectPaymentDisplayName);
  const braintreeDisplayName = '';
  const paymentDisplayName =
    braintreeDisplayName || clientPaymentName || clientName;

  const handleCanMakePayment = (canMakePayment) => {
    dispatch(setCanMakePayment(canMakePayment));
  };

  const handleResetPaymentError = () => {
    dispatch(resetPaymentError());
  };

  const handleSetBraintreeError = (errorMessage) => {
    dispatch(setPaymentFailed(errorMessage));
  };

  const submitPayment = (instance) => {
    // Start payment
    dispatch(setupPaymentRequest(instance, venueId, areaId, accountId, history));
  };

  const handlePaymentChange = () => {
    if (instance && instance.isPaymentMethodRequestable()) {
      handleCanMakePayment(true);
    } else {
      handleCanMakePayment(false);
    }
  };

  const paymentOptions = {
    authorization: clientToken,
    dataCollector: {
      kount: true,
    },
    paypal: {
      flow: 'checkout',
      amount,
      currency,
    },

    applePay: {
      displayName: 'My Store',
      paymentRequest: {
        total: {
          label: paymentDisplayName,
          amount: String(amount),
        },
        requiredBillingContactFields: ['postalAddress'],
      },
    },
    threeDSecure: true,
  };

  const googlePayOptions = {
    googlePay: {
      googlePayVersion: 2,
      merchantId: googleMerchantId,
      transactionInfo: {
        totalPriceStatus: 'FINAL',
        totalPrice: String(amount),
        currencyCode: currency,
      },
    },
  };

  const dropInOptions = navigator.cookieEnabled
    ? { ...paymentOptions, ...googlePayOptions }
    : paymentOptions;

  useEffect(() => {
    if (clientToken) {
      const dropInSetup = () => {
        braintreeClient.create(
          { authorization: clientToken },
          (createErr, clientInstance) => {
            if (createErr) {
              handleSetBraintreeError('Error connecting to client');
              return;
            }
            const threeDSecure =
              clientInstance.getConfiguration().gatewayConfiguration
                .threeDSecureEnabled;
            if (dropInOptions.threeDSecure !== threeDSecure) {
              dropInOptions.threeDSecure = threeDSecure;
            }
            BraintreeWebDropIn.create(
              {
                // Not sure what to replace this with, so "allowing it" for now
                // eslint-disable-next-line react/no-find-dom-node
                container: ReactDOM.findDOMNode(wrapper),
                ...dropInOptions,
              },
              (dropInError, btInstance) => {
                if (dropInError) {
                  handleSetBraintreeError(
                    'Error connecting to payment service',
                  );
                  return;
                }
                if (btInstance) {
                  instance = btInstance;
                  instance.on(
                    'noPaymentMethodRequestable',
                    handlePaymentChange,
                  );
                  instance.on('paymentMethodRequestable', handlePaymentChange);
                }
              },
            );
          },
        );
      };
      dropInSetup();
    }
    return () => {
      instance && instance.teardown();
      instance = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken]);

  useEffect(() => {
    return () => {
      handleResetPaymentError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasBill ? (
    <DefaultLayout
      title={'Payment'}
      error={
        braintree.failed &&
        (braintree.errorMessage ||
          'Unfortunately there has been an error processing your payment. Please try another payment method or try again.')
      }
    >
      {braintree.initializing || !clientToken ? (
        <Loader />
      ) : braintree.processing ? (
        <Loader text="Placing Order" />
      ) : (
        <>
          <div ref={(ref) => (wrapper = ref)} />
          <Row>
            <Col xs={6}>
              <Link
                id="button-cancel"
                className="btn btn-default"
                to={`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}/guest-checkout`}
                onClick={handleResetPaymentError}
              >
                {dictionary?.buttons?.cancel}
              </Link>
            </Col>
            <Col xs={6}>
              <ButtonToolbar bsClass="text-right">
                <PayButton
                  id="button-confirm"
                  handleSubmit={() => submitPayment(instance)}
                >
                  {dictionary?.buttons?.confirm}{' '}
                  <FormattedCurrency value={amount} />
                </PayButton>
              </ButtonToolbar>
            </Col>
          </Row>
        </>
      )}
    </DefaultLayout>
  ) : (
    <Redirect
      to={`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}`}
    />
  );
};
