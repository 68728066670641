import cs from 'classnames';
import { TipPresetButton } from 'checkout/components/Tip/components';
import { selectSelectedTipPreset } from 'checkout/selectors';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { useEffect } from 'react';
import { useCheckoutDetails } from 'contexts/CheckoutContext';

interface TipButtonsProps {
  handlePresetClick: (percentage: number) => void;
  calculateTipAmount: (percentage: number) => string;
}

export const TipPresetButtonList: React.FC<TipButtonsProps> = ({
  handlePresetClick,
  calculateTipAmount,
}) => {
  const { tipPresets } = useConfig();
  const tipPercentage = useSelector(selectSelectedTipPreset);
  const { basketTotal } = useCheckoutDetails();

  const classNames = (preset: number): string => {
    return cs(
      'tip-selector-button',
      tipPercentage.toFixed(2) === preset.toFixed(2) && 'btn-primary',
    );
  };

  useEffect(() => {
    if (tipPercentage !== 0 && basketTotal !== 0) {
      scroller.scrollTo(`tip-preset-button-id${tipPercentage}`, {
        smooth: 'easeOutQuint',
        horizontal: true,
        containerId: 'tip-button-container',
        offset: -25,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="tip-button-container" className="form-group tip-button-container">
      <TipPresetButton
        preset={0}
        handlePresetClick={handlePresetClick}
        calculateTipAmount={calculateTipAmount}
        classNames={classNames}
      />
      {tipPresets.map((preset) => (
        <TipPresetButton
          key={`tip-preset-${preset}`}
          preset={preset}
          handlePresetClick={handlePresetClick}
          calculateTipAmount={calculateTipAmount}
          classNames={classNames}
        />
      ))}
      <TipPresetButton
        preset={-1}
        handlePresetClick={handlePresetClick}
        calculateTipAmount={calculateTipAmount}
        classNames={classNames}
      />
    </div>
  );
};
