import {
  CardNumber,
  Email,
  Pin,
  Text,
  Password,
  PhoneNumber,
  VirtualSelect,
} from 'core/components/RHF/FieldTemplates';
import { Field } from 'types/models/Forms';

interface FieldGeneratorProps {
  field: Field;
}

export const FieldGenerator: React.FC<FieldGeneratorProps> = ({ field }) => {
  // if we have the userField Type, use it rather than the type
  // that allows us to try and determine the type, and then default to text

  const formFieldType =
    field.userFieldType !== undefined && field.userFieldType !== ''
      ? field.userFieldType
      : field.name;

  switch (formFieldType) {
    case 'title':
      return (
        <VirtualSelect formField={field} autocomplete="honorific-prefix" />
      );

    case 'firstName':
    case 'first_name':
      return <Text field={field} autocomplete="given-name" />;

    case 'lastName':
    case 'last_name':
      return <Text field={field} autocomplete="family-name" />;

    case 'address1':
      return <Text field={field} autocomplete="address-line1" />;

    case 'address2':
      return <Text field={field} autocomplete="address-line2" />;

    case 'address3':
      return <Text field={field} autocomplete="address-line3" />;

    case 'country':
      return <Text field={field} autocomplete="country-name" />;

    case 'mobile_number':
    case 'mobile_phone':
    case 'home_phone':
      return <PhoneNumber field={field} />;

    case 'email':
    case 'loyalty_email':
      return <Email field={field} />;

    case 'cardNumber':
    case 'loyalty_card':
      return <CardNumber field={field} />;

    //We're not passing in an autocomplete here because:
    // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
    case 'password':
      return <Password field={field} />;

    case 'existing_password':
      return <Password field={field} autocomplete="current-password" />;

    case 'pin':
    case 'loyalty_pin':
      return <Pin field={field} />;

    case 'postcode':
      return <Text field={field} autocomplete="postal-code" />;

    case 'county':
    case 'extra1':
    case 'extra2':
    case 'extra3':
    case 'extra4':
    case 'extra5':
    case 'extra6':
    case 'extra7':
    case 'extra8':
    case 'extra9':
    case 'extra10':
    case 'promo_code':
    case 'town':
    case 'field_1':
    case 'field_2':
      return <Text field={field} />;

    case 'dob':
      return null; //TODO - something here

    case 'text':
      return <Text field={field} />;

    //TODO - checkboxes for the registration and edit forms

    default:
      return null;
  }
};
