export const initialState = {
  braintree: {
    initializing: false,
    clientToken: null,
    initialized: false,
    processing: false,
    complete: false,
    failed: false,
    errorMessage: null,
  },
  canMakePayment: false,
  guestEmailAddress: null,
};
