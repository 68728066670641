import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCanMakePayment } from 'PayMyBill/checkout/selectors';

interface PayButtonProps {
  handleSubmit: () => void;
  children: React.ReactNode;
}

export const PayButton: React.FC<PayButtonProps> = ({
  handleSubmit,
  children,
}) => {
  const canMakePayment = useSelector(selectCanMakePayment);
  return (
    <Button
      disabled={!canMakePayment}
      onClick={handleSubmit}
      bsClass="btn btn-primary"
      data-testid="pay-my-bill-button-confirm-pay"
    >
      {children}
    </Button>
  );
};
