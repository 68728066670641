import { Link } from 'react-router-dom';
import { Panel, Row, Col, ButtonToolbar } from 'react-bootstrap';
import { addressToString } from 'venue/utils';
import { FormattedDistance } from 'common/components/FormattedDistance';
import { selectVenue } from 'PayMyBill/venues/selectors';
import { useSelector } from 'react-redux';
import { VenueSalesArea, ServiceType } from 'types/models';

interface VenueItemProps {
  venueId: number;
}

export const VenueItem: React.FC<VenueItemProps> = ({ venueId }) => {
  const venue = useSelector(selectVenue(venueId));
  const checkSalesAreasAvailability = (salesArea: VenueSalesArea[]) => {
    return salesArea.some(
      (area) => area.canOrder && area.services.includes(ServiceType.PayMyBill),
    );
  };

  const isVenueAvailable =
    venue.canOrder &&
    venue.salesArea.length > 0 &&
    venue.services.includes(ServiceType.PayMyBill) &&
    checkSalesAreasAvailability(venue.salesArea);

  return (
    <Panel id={`venue-${venue.id}`}>
      <Panel.Body>
        <Row>
          <Col xs={8}>
            <h4>
              {venue.name}
              <FormattedDistance
                distance={venue.distance}
                showIcon={false}
                additionalClassName={'text-muted'}
                showBrackets={true}
              />
            </h4>
            <small>
              {venue.address && addressToString(venue.address, ', ')}
            </small>
          </Col>
          <Col xs={4}>
            <ButtonToolbar bsClass="text-right">
              {isVenueAvailable ? (
                <Link
                  className="btn btn-primary"
                  data-testid="pay-my-bill-venue-button-select"
                  to={`/pay-my-bill/venue/${venue.id}`}
                >
                  Select
                </Link>
              ) : (
                <button className="btn btn-primary" disabled>
                  Select
                </button>
              )}
            </ButtonToolbar>
          </Col>
        </Row>
      </Panel.Body>
    </Panel>
  );
};
