import {
  SelectedServiceClickAndCollect,
  SelectedServiceDeliveryToLocation,
  SelectedServiceOrderAndPay,
} from 'venue/components/SelectedServiceChoiceModal';
import { ServiceType } from 'types/models';
import { useServices, useVenues } from 'contexts/VenueContext';

export const SelectedServiceChoiceSwitch: React.FC = () => {
  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();

  if (!selectedVenue) return null;

  switch (selectedService) {
    case ServiceType.ClickAndCollect:
      return <SelectedServiceClickAndCollect />;
    case ServiceType.DeliveryToLocation:
      return <SelectedServiceDeliveryToLocation />;
    case ServiceType.OrderAndPay:
      return <SelectedServiceOrderAndPay />;
    default:
      //return nothing, this modal should only work if you actually have a service
      //might be a better way to handle this?
      return null;
  }
};
