import { Image } from 'react-bootstrap';
import { DefaultPageLayout } from 'PayMyBill/core/layouts';

interface ListLayoutProps {
  children: React.ReactElement;
  bannerImage?: string;
  headerCopy?: string;
}

export const ListLayout: React.FC<ListLayoutProps> = ({
  children,
  bannerImage = '',
  headerCopy = '',
}) => {
  return (
    <DefaultPageLayout>
      {bannerImage && <Image src={bannerImage} responsive rounded />}
      {headerCopy && <p>{headerCopy}</p>}
      {children}
    </DefaultPageLayout>
  );
};
