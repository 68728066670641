import { Map, List } from 'immutable';

/* eslint-disable-next-line */
// Below was used to build config into bundle but this requires re-build everytime config changes.
// const prodConfig = require(`${__dirname}/../../clients/${process.env.CLIENT}/config/config.json`);

// Set a default config and map the incoming changes over it.
// This allows us to define a strict config structure and also serve as a reference for available options during development.
const defaultConfig = Map({
  pmb: Map({
    findVenue: Map({
      dictionary: Map({
        title: null,
        errors: Map({}),
        buttons: Map({
          singleSalesArea: null,
        }),
      }),
      settings: Map({
        bannerImage: null,
        headerCopy: null,
      }),
    }),
    tableNumber: Map({
      dictionary: Map({
        title: null,
        errors: Map({
          multipleAccounts: Map({
            title: null,
            body: null,
          }),
          notFound: null,
        }),
        buttons: Map({
          cancel: null,
          confirm: null,
        }),
      }),
      settings: Map({
        bannerImage: null,
        headerCopy: null,
        multipleAccounts: false,
      }),
    }),
    billReview: Map({
      dictionary: Map({
        title: null,
        errors: Map({
          accountPaid: null,
        }),
        buttons: Map({
          addTip: null,
          cancel: null,
          confirm: null,
          modifyTip: null,
        }),
      }),
      settings: Map({
        enableTip: true,
      }),
    }),
    addTip: Map({
      dictionary: Map({
        title: null,
        errors: Map({}),
        buttons: Map({
          cancel: null,
          confirm: null,
        }),
      }),
      settings: Map({
        presets: List([5, 10, 15, 20]),
      }),
    }),
    pay: Map({
      dictionary: Map({
        title: null,
        errors: Map({}),
        buttons: Map({
          cancel: null,
          confirm: null,
        }),
      }),
    }),
    guestCheckout: Map({
      dictionary: Map({
        title: null,
        errors: Map({}),
        buttons: Map({
          cancel: null,
          confirm: null,
        }),
      }),
    }),
  }),
});

export const setConfig = (config) => {
  return typeof config === 'undefined'
    ? defaultConfig
    : Map({
        acceptsTip: false,
        tipPresets: [],
        ...config,
        pmb: defaultConfig.get('pmb').mergeDeep(config.pmb),
      });
};

export const initialState = Map({
  // config: setConfig(CONFIG),
  currency: 'GBP',
  locale: 'en',
  notification: Map({
    message: null,
    level: null,
    code: null, // Should match Bootstrap's variants of success, warning, danger, or info
  }),
  cookiesDisabledAcknowledgement: false,
});

export const confirmPassword = (matchName) => (value, allValues) =>
  value !== allValues.get(matchName) ? 'Passwords must match' : undefined;
