import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { Field as ImmutableField } from 'redux-form/immutable';
import { FieldGroup } from '../FormComponents';
import {
  dob,
  max,
  min,
  mobile,
  names,
  required,
  yearLow,
  yearMax,
} from 'core/validation';
import { changeDOB } from 'user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefaultBirthday } from 'user/reducer';
import { getPropertyCaseInsensitive } from 'utils';

/**
 * @deprecated
 */
export const Birthday = ({ field }) => {
  let dropdowns;

  const getDays = (amount) =>
    Array(amount)
      .fill(null)
      .map((x, i) => i + 1);

  const getDates = () => [
    ['January', getDays(31)],
    ['February', getDays(29)],
    ['March', getDays(31)],
    ['April', getDays(30)],
    ['May', getDays(31)],
    ['June', getDays(30)],
    ['July', getDays(31)],
    ['August', getDays(31)],
    ['September', getDays(30)],
    ['October', getDays(31)],
    ['November', getDays(30)],
    ['December', getDays(31)],
  ];

  const getYears = () => {
    const years = [];
    const lastYear = new Date().getFullYear() - 16;
    let firstYear = 1901;

    while (firstYear < lastYear) {
      years.push(firstYear);
      firstYear += 1;
    }
    return years;
  };

  const dates = [getDates(), getYears()];
  const defaultBirthday = useSelector(selectDefaultBirthday).toJS();
  const dispatch = useDispatch();

  const updateDOB = (type, value) => {
    dispatch(changeDOB(type, value));
  };

  switch (getPropertyCaseInsensitive(field, 'dropDownmode')) {
    case 'YYYY-MM-DD':
      dropdowns = (
        <Row>
          <Col sm={4}>
            <FormControl
              id="dob-yyyy"
              componentClass="select"
              placeholder=""
              value={parseInt(
                defaultBirthday ? defaultBirthday.year : 1990,
                10,
              )}
              onChange={(e) => updateDOB('year', e.target.value)}
            >
              {dates[1].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FormControl>
          </Col>
          <Col sm={4}>
            <FormControl
              id="dob-mm"
              componentClass="select"
              placeholder=""
              value={parseInt(defaultBirthday ? defaultBirthday.month : 1, 10)}
              onChange={(e) => updateDOB('month', e.target.value)}
            >
              {dates[0].map((month, i) => (
                <option key={month[0]} value={i + 1}>
                  {month[0]}
                </option>
              ))}
            </FormControl>
          </Col>
          <Col sm={4}>
            <FormControl
              id="dob-dd"
              componentClass="select"
              placeholder=""
              value={defaultBirthday ? defaultBirthday.day : 1}
              onChange={(e) => updateDOB('day', e.target.value)}
            >
              {dates[0][
                parseInt(defaultBirthday ? defaultBirthday.month : 0, 10) - 1 ||
                  0
              ][1].map((day) => (
                <option key={day} value={day}>
                  {day}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
      );
      break;

    case 'YYYY-MM':
      dropdowns = (
        <Row>
          <Col sm={4}>
            <FormControl
              id="dob-yyyy"
              componentClass="select"
              placeholder=""
              value={parseInt(
                defaultBirthday ? defaultBirthday.year : 1990,
                10,
              )}
              onChange={(e) => updateDOB('year', e.target.value)}
            >
              {dates[1].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FormControl>
          </Col>
          <Col sm={4}>
            <FormControl
              id="dob-mm"
              componentClass="select"
              placeholder=""
              value={parseInt(defaultBirthday ? defaultBirthday.month : 1, 10)}
              onChange={(e) => updateDOB('month', e.target.value)}
            >
              {dates[0].map((month, i) => (
                <option key={month[0]} value={i + 1}>
                  {month[0]}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
      );
      break;

    case 'MM-YYYY':
      dropdowns = (
        <Row>
          <Col sm={4}>
            <FormControl
              id="dob-mm"
              componentClass="select"
              placeholder=""
              value={parseInt(defaultBirthday ? defaultBirthday.month : 1, 10)}
              onChange={(e) => updateDOB('month', e.target.value)}
            >
              {dates[0].map((month, i) => (
                <option key={month[0]} value={i + 1}>
                  {month[0]}
                </option>
              ))}
            </FormControl>
          </Col>
          <Col sm={4}>
            <FormControl
              id="dob-yyyy"
              componentClass="select"
              placeholder=""
              value={parseInt(
                defaultBirthday ? defaultBirthday.year : 1990,
                10,
              )}
              onChange={(e) => updateDOB('year', e.target.value)}
            >
              {dates[1].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </FormControl>
          </Col>
        </Row>
      );
      break;

    default:
      dropdowns = (
        <ImmutableField
          name={field.name}
          component={FieldGroup}
          type="text"
          placeholder={field.placeholder}
          validate={[
            min(field.min),
            max(field.max),
            mobile(field.isMobile),
            dob(field.isDob),
            yearLow(field.isDob),
            yearMax(field.isDob),
            names(field.isName),
            required(field.required),
          ]}
        />
      );
  }

  return (
    <Col sm={field.width}>
      <FormGroup>
        <ControlLabel>{field.label}</ControlLabel>
        {dropdowns}
      </FormGroup>
    </Col>
  );
};
