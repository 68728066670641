export const initialState = {
  fetch: {
    abortController: null,
    aborted: false,
    error: null,
    loading: false,
    response: null,
  },
  fetched: false,
  accounts: null,
  venues: [],
};

export const filterAccounts = (accounts, tableNumber) => {
  // Match the number the user searched exactly with an optional single letter at the end to catch mutliple accounts per table
  const regex = new RegExp(`^${tableNumber}[a-zA-Z]?$`);

  return accounts.filter((v) => regex.test(v.TableNumber));
};
