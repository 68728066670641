import { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Col, Form, FormGroup, Panel } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form/immutable';
import { Sidebar } from '.';
import { FieldGroup } from 'core/components/FormComponents';
import { required, min } from 'core/validation';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsFetchingResetPassword } from 'user/selectors';
import axios from 'axios';
import { addNotification } from 'core/actions';
import { changePassword } from 'user/actions';
import { useHistory } from 'react-router';

let captcha;

class Captcha extends Component {
  render() {
    const {
      input: { onChange },
      sitekey,
    } = this.props;

    return (
      <ReCAPTCHA
        ref={(el) => {
          captcha = el;
        }}
        size="invisible"
        sitekey={sitekey}
        onChange={onChange}
      />
    );
  }
}

const oldPassValidation = [required(true)];

const newPassValidation = [required(true), min(8)];

const PasswordChange = ({ handleSubmit }) => {
  const { devCaptchaSitekey, prodCaptchaSitekey } = useConfig();
  let button;

  const dispatch = useDispatch();
  const history = useHistory();

  const isFetchingChangePassword = useSelector(selectIsFetchingResetPassword);

  const handleChangePassword = (passwords, captcha) => {
    if (captcha && captcha.getValue()) {
      axios
        .post(`/recaptcha?response=${captcha.getValue()}`)
        .then((response) => {
          if (response.data.success) {
            if (
              passwords.get('new-password') ===
              passwords.get('confirm-password')
            ) {
              dispatch(changePassword(passwords, history));
            } else {
              dispatch(
                addNotification(
                  'New password and confirmation do not match.',
                  'danger',
                ),
              );
            }
          }
        });
    }

    captcha.reset();
  };

  if (isFetchingChangePassword) {
    button = (
      <Button bsStyle="primary" type="submit" disabled>
        Loading
      </Button>
    );
  } else {
    button = (
      <Button
        bsStyle="primary"
        type="submit"
        onClick={handleSubmit(() => captcha.execute())}
        data-testid="submit-password-change-button"
      >
        Save
      </Button>
    );
  }

  return (
    <div>
      <Sidebar />
      <Col sm={9}>
        <Panel>
          <h2>Change Password</h2>
          <Form onSubmit={handleSubmit(() => captcha.execute())}>
            <Field
              name="captcha"
              component={Captcha}
              sitekey={
                prodCaptchaSitekey ||
                devCaptchaSitekey ||
                '6LdqtnoUAAAAAPuSlZ2wVCCtmQGYNd-PvSfaFVQw'
              }
              onChange={handleSubmit((passwords) =>
                handleChangePassword(passwords, captcha),
              )}
            />
            <Field
              name="old-password"
              component={FieldGroup}
              type="password"
              placeholder="Old Password"
              label="Old Password"
              validate={oldPassValidation}
            />
            <Field
              name="new-password"
              component={FieldGroup}
              type="password"
              placeholder="New Password"
              label="New Password"
              validate={newPassValidation}
            />
            <Field
              name="confirm-password"
              component={FieldGroup}
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
              validate={newPassValidation}
            />
            <FormGroup>{button}</FormGroup>
          </Form>
        </Panel>
      </Col>
    </div>
  );
};

export default reduxForm({ form: 'change-password' })(PasswordChange);
