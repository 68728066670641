import { Choice } from 'types/models';
import { MenuChoice } from 'menu/components/MenuModal/ModalComponents';

interface NestedChoiceGroupProps {
  nestedChoice: Choice;
  nestedPortionId: number;
  updateChoice: (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
    hasNestedChoice?: boolean,
  ) => void;
}

export const NestedChoiceGroup: React.FC<NestedChoiceGroupProps> = ({
  nestedChoice,
  nestedPortionId,
  updateChoice,
}) => {
  const choiceGroup = nestedChoice.choiceGroup;

  if (!choiceGroup) return null;

  const displayRecord = nestedChoice.displayRecordToUse;

  return (
    <div className="choice-group-section">
      <h2>{displayRecord?.name}</h2>
      <div className="choice-group-description text-muted">
        {displayRecord?.description}
      </div>
      {nestedChoice.choiceGroup?.choices.map((c, idx) => {
        return (
          <MenuChoice
            key={`choice:${c.productId}`}
            choice={c} // this individual choice we want to show
            choiceIndex={-1} //todo - figure out if we need this
            choiceGroupIndex={idx}
            choiceGroup={choiceGroup}
            portionId={nestedPortionId}
            itemsInChoiceGroup={choiceGroup.choices.length}
            updateChoice={updateChoice}
            isNestedChoice={true}
          />
        );
      })}
      <div className="clearfix" />
    </div>
  );
};
