import { RootStateOrAny } from 'react-redux';

export const selectBillTotalToPay = (state: RootStateOrAny): string => {
  return Number(
    Number(state.payMyBill?.bill?.data?.outstandingBalanceToPay) +
      Number(state.payMyBill?.bill?.tipAmount),
  ).toFixed(2);
};

export const selectGuestEmail = (state: RootStateOrAny): string => {
  return state.payMyBill.checkout.guestEmailAddress;
};

export const selectCanMakePayment = (state: RootStateOrAny): boolean => {
  return state.payMyBill.checkout.canMakePayment;
};

export const selectClientToken = (state: RootStateOrAny): string => {
  return state.payMyBill.checkout.braintree.clientToken;
};
