import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field as ImmutableField } from 'redux-form/immutable';
import { CheckboxFieldGroup } from 'core/components/FormComponents';
import { required } from 'core/validation';
import { Field } from 'types/models/Forms';

interface CheckboxProps {
  field: Field;
}

/**
 * @deprecated
 */
export const Checkbox: React.FC<CheckboxProps> = ({ field }) => {
  const [validation, setValidation] = useState<
    ((arg: unknown) => string | undefined)[]
  >([]);

  useEffect(() => {
    const fieldRequired = required(field.required);

    setValidation([fieldRequired]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width || 12}>
      <ImmutableField
        name={field.name}
        component={CheckboxFieldGroup}
        type="checkbox"
        label={field.label}
        validate={validation}
      />
    </Col>
  );
};
