import { Link } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import { AccountSummary } from 'types/models/PayMyBill';

interface TableListItemProps {
  account: AccountSummary;
  path: string;
}

export const TableListItem: React.FC<TableListItemProps> = ({
  account,
  path,
}) => (
  <ListGroupItem>
    <Link to={`${path}/${account.AccountId}`}>
      Table #{account.TableNumber}
    </Link>
  </ListGroupItem>
);
