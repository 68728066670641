import { MenuGroupItemTextField } from 'types/models';
import dompurify from 'dompurify';
import { useConfig } from 'contexts/ConfigContext';

interface TextFieldItemProps {
  textFieldItem: MenuGroupItemTextField;
}

export const TextFieldItem: React.FC<TextFieldItemProps> = ({
  textFieldItem,
}) => {
  const { galleryViewEnabled } = useConfig();
  const sanitizer = dompurify.sanitize; //saniti(z/s)ing the HTML

  dompurify.addHook('afterSanitizeAttributes', function (node) {
    // set all elements owning target to target=_blank
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });

  return (
    <div className={galleryViewEnabled ? 'gallery-text' : ''}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(textFieldItem.textField.text),
        }}
      />
    </div>
  );
};
