import { useEffect, useState } from 'react';
import { useConfig } from 'contexts/ConfigContext';
import { useHotels } from 'contexts/HotelContext';
import {
  ContextualLoader,
  ContextualLoaderError,
} from 'components/ContextualLoader';
import {
  useCheckout,
  useCheckoutDetails,
  usePayment,
} from 'contexts/CheckoutContext';

interface PaymentContextualLoaderProps {
  loadingText?: string;
  isOpaque?: boolean;
  datatestid?: string;
}

export const PaymentContextualLoader: React.FC<
  PaymentContextualLoaderProps
> = ({ loadingText, isOpaque, datatestid }) => {
  const { googleMerchantId, acceptsPayment } = useConfig();

  const {
    paymentInitError,
    isSubmittingPayment,
    isApplePayEnabled,
    isApplePayReady,
    isGooglePayEnabled,
    isCardReady,
    isGooglePayReady,
    isPayPalEnabled,
    isPayPalReady,
  } = usePayment();

  const { isFetchingBasket } = useCheckout();

  const {
    chargeToRoom: { isSubmittingChargeToRoom },
  } = useHotels();

  const [allReady, setAllReady] = useState(false);

  const { basketTotal } = useCheckoutDetails();

  useEffect(() => {
    if (basketTotal === 0) {
      setAllReady(true);
      return;
    }

    if (!acceptsPayment) {
      setAllReady(true);
      return;
    }

    const paypal = isPayPalEnabled ? isPayPalReady : true;
    const googlePay =
      isGooglePayEnabled && navigator.cookieEnabled ? isGooglePayReady : true;
    const applePay = isApplePayEnabled ? isApplePayReady : true;

    setAllReady(
      paypal &&
        googlePay &&
        isCardReady &&
        applePay &&
        !paymentInitError &&
        !isFetchingBasket,
    );
  }, [
    acceptsPayment,
    isApplePayEnabled,
    isApplePayReady,
    basketTotal,
    isCardReady,
    googleMerchantId,
    isGooglePayEnabled,
    isGooglePayReady,
    isPayPalEnabled,
    isPayPalReady,
    paymentInitError,
    isFetchingBasket,
  ]);

  if (isSubmittingPayment || isSubmittingChargeToRoom) {
    return <ContextualLoader isVisible={true} loaderText="Placing Order" />;
  }

  if (paymentInitError) {
    return (
      <ContextualLoaderError
        errorMessage="There was an error loading payment methods"
        isVisible={paymentInitError}
      />
    );
  }

  return (
    <ContextualLoader
      isVisible={!allReady}
      loaderText={loadingText ?? 'Loading Payment Options'}
      isOpaque={isOpaque ?? true}
      datatestid={datatestid}
    />
  );
};
