import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field as ImmutableField } from 'redux-form/immutable';
import { FieldGroup } from '../FormComponents';
import { email, min, max, required } from 'core/validation';
import { Field } from 'types/models/Forms';

interface EmailProps {
  field: Field;
}

/**
 * @deprecated
 */
export const Email: React.FC<EmailProps> = ({ field }) => {
  const [validation, setValidation] = useState<
    ((arg: never) => string | undefined)[]
  >([]);

  useEffect(() => {
    let tempValidation = [];

    const fieldIsEmail = email;
    const fieldMin = min(field.min);
    const fieldMax = max(field.max);
    const fieldRequired = required(field.required);

    tempValidation = [fieldIsEmail, fieldMin, fieldMax, fieldRequired];

    setValidation(tempValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width}>
      <ImmutableField
        name={field.name}
        component={FieldGroup}
        type="email"
        placeholder={field.placeholder}
        label={field.label}
        validate={validation}
        autoComplete="email"
      />
    </Col>
  );
};
