import { ReactElement } from 'react';

import { Loader } from 'components/Loader';

interface LoadingOrRenderProps {
  isLoading: boolean;
  children?: ReactElement;
}

export const LoadingOrRender: React.FC<LoadingOrRenderProps> = ({
  isLoading,
  children,
}) => {
  return isLoading ? <Loader /> : children ? children : null;
};
