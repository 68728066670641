import { Fragment } from 'react';
import { Panel } from 'react-bootstrap';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import {
  BillTable,
  BillTableProductRow,
  BillTableRow,
  BillTableTaxRow,
} from 'PayMyBill/bill/components/billDisplay';
import { FormattedCurrency } from 'common/components/FormattedCurrency';
import { useConfig, usePhrases } from 'contexts/ConfigContext';

export const BasicBill = ({ bill, tipAmount, hasTip }) => {
  const params = useParams();
  const { pmb, supportsTip } = useConfig();
  const { tipButtonText, payMyBillBalanceText, payMyBillTotalText } =
    usePhrases();

  const { dictionary } = pmb.billReview;

  return (
    <>
      {bill.courses.map(
        (course, i) =>
          course.items.length > 0 && (
            <Fragment key={`${i}-${course.id}`}>
              <Panel.Heading>
                <Panel.Title>{course.name}</Panel.Title>
              </Panel.Heading>
              <BillTable>
                {course.items.map((v, i) => (
                  <BillTableProductRow data={v} key={`${i}-${v.productId}`} />
                ))}
              </BillTable>
            </Fragment>
          ),
      )}
      {bill.promotions.items.length > 0 && (
        <>
          <Panel.Heading>
            <Panel.Title>Promotions</Panel.Title>
          </Panel.Heading>
          <BillTable>
            {bill.promotions.items.map((v, i) => (
              <BillTableRow data={v} key={`promotion-${i}`} promotion />
            ))}
          </BillTable>
          <hr />
        </>
      )}
      {bill.taxes?.length > 0 ? (
        <>
          <hr />
          <BillTable>
            {bill.taxes?.map((tax) => (
              <BillTableTaxRow
                key={`tax-${tax.id}`}
                name={tax.name}
                amount={tax.amount}
              />
            ))}
          </BillTable>
        </>
      ) : null}
      <BillTable>
        <hr />
        <BillTableRow
          data={{
            total: bill.accountTotal,
            display: { name: payMyBillTotalText },
          }}
        />
      </BillTable>
      <hr />
      <BillTable>
        {bill.payments.total > 0 && (
          <BillTableRow
            data={{
              display: {
                name: 'Payments',
              },
              total: bill.payments.total,
            }}
            reduction
          />
        )}
        {supportsTip && bill.outstandingBalanceToPay > 0 ? (
          <div className="bill-table-row">
            <div>
              <Link
                id="button-tip"
                data-testid="button-set-tip"
                to={`/pay-my-bill/venue/${params.venueId}/${params.areaId}/account/${params.accountId}/add-tip`}
              >
                {hasTip ? dictionary?.buttons?.modifyTip : tipButtonText}
              </Link>
            </div>
            <div className="text-right">
              <FormattedCurrency value={tipAmount} data-testid="tip-input" />
            </div>
          </div>
        ) : null}
        <BillTableRow
          additionalClasses="pmb-balance"
          data={{
            total: Number(bill.outstandingBalanceToPay) + Number(tipAmount),
            display: {
              name: payMyBillBalanceText,
            },
          }}
        />
      </BillTable>
    </>
  );
};
