import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

export const SelectFieldGroup = ({ input, label, children }) => (
  <FormGroup controlId={input.name}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl
      componentClass="select"
      placeholder={input.placeholder}
      {...input}
    >
      {children}
    </FormControl>
  </FormGroup>
);
