const namespace = 'pmb/checkout/';

export const SET_BRAINTREE_CLIENT_TOKEN = `${namespace}SET_BRAINTREE_CLIENT_TOKEN`;
export const SET_INITIALIZING = `${namespace}SET_INITIALIZING`;
export const SET_INITIALIZED = `${namespace}SET_INITIALIZED`;
export const SET_PAYMENT_PROCESSING = `${namespace}SET_PAYMENT_PROCESSING`;
export const SET_PAYMENT_COMPLETE = `${namespace}SET_PAYMENT_COMPLETE`;
export const SET_PAYMENT_FAILED = `${namespace}SET_PAYMENT_FAILED`;
export const SET_GUEST_EMAIL_ADDRESS = `${namespace}SET_GUEST_EMAIL_ADDRESS`;
export const SET_CAN_MAKE_PAYMENT = `${namespace}SET_CAN_MAKE_PAYMENT`;
export const RESET_BRAINTREE = `${namespace}RESET_BRAINTREE`;
