import { fetchBodyFormatter, setupFetchParams } from 'utils';
import {
  RECEIVE_ACCOUNTS,
  REQUEST_ACCOUNTS,
  RESET_SEARCH_FLAG,
  SET_ACCOUNT_SEARCH_ERROR,
} from 'PayMyBill/table/actionTypes';
import { filterAccounts } from 'PayMyBill/table/model';

const receiveAccounts = (response, accounts) => ({
  type: RECEIVE_ACCOUNTS,
  accounts,
  response,
});

const requestAccounts = () => ({
  type: REQUEST_ACCOUNTS,
});

const searchOpenAccounts = (body) => (dispatch) => {
  const fetchParams = setupFetchParams();

  dispatch(requestAccounts());

  return fetch(fetchParams.url, {
    method: 'POST',
    headers: {
      ...fetchParams.headers,
    },
    body: fetchBodyFormatter({
      method: 'searchOpenAccounts',
      ...fetchParams.body,
      ...body,
    }),
  })
    .then((res) => res.ok && res.json())
    .then((json) => {
      dispatch(
        receiveAccounts(json.results, filterAccounts(json.results, body.table)),
      );
    })
    .catch(() => {
      dispatch(setAccountSearchError());
    });
};

export const searchOpenAccountsByTableNumber =
  (venue, area, table) => (dispatch) => {
    dispatch(
      searchOpenAccounts({
        siteId: venue,
        salesAreaId: area,
        table,
      }),
    );
  };

export const setAccountSearchError = () => ({
  type: SET_ACCOUNT_SEARCH_ERROR,
});

export const resetHasSearched = () => ({
  type: RESET_SEARCH_FLAG,
});
