import { AztecChoice, Choice } from 'types/models';
import { MenuChoice } from 'menu/components/MenuModal/ModalComponents';

interface ChoiceGroupProps {
  choice: AztecChoice;
  choiceIndex: number;
  updateChoice: (
    choiceIndex: number,
    choiceGroupIndex: number,
    quantity?: number,
    hasNestedChoice?: boolean,
  ) => void;
}

export const ChoiceGroup: React.FC<ChoiceGroupProps> = ({
  choice,
  choiceIndex,
  updateChoice,
}) => {
  const choiceGroup = choice.choiceGroup;

  if (!choiceGroup) return null;

  const displayRecord = choiceGroup?.displayRecords[choice.displayRecordId];

  const getKey = (c: Choice) => {
    if (c.choiceId) {
      return `choice:${c.choiceId}`;
    } else if (c.productId) {
      return `choice:${c.productId}`;
    }
  };

  return (
    <div className="choice-group-section">
      <h2>{displayRecord?.name}</h2>
      <div className="choice-group-description text-muted">
        {displayRecord?.description}
      </div>
      {choice.choiceGroup?.choices.map((c, idx) => {
        return (
          <MenuChoice
            key={getKey(c)}
            choice={c} // this individual choice we want to show
            choiceIndex={choiceIndex}
            choiceGroupIndex={idx}
            choiceGroup={choiceGroup}
            portionId={choice.portionId}
            itemsInChoiceGroup={choiceGroup.choices.length}
            updateChoice={updateChoice}
          />
        );
      })}
      <div className="clearfix" />
    </div>
  );
};
