import { initialState, formatBill } from 'PayMyBill/bill/model';
import {
  REQUEST_BILL,
  RECEIVE_BILL,
  ADD_TIP,
  RESET_BILL,
} from 'PayMyBill/bill/actionTypes';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BILL:
      return initialState;
    case REQUEST_BILL:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          loading: true,
        },
      };
    case RECEIVE_BILL:
      return {
        ...state,
        data: formatBill(action.response),
        fetch: {
          ...state.fetch,
          loading: false,
          response: action.response,
        },
        tableNumber: action.response.account.tableNumber,
      };
    case ADD_TIP:
      return {
        ...state,
        tipAmount: Number(action.tipAmount).toFixed(2),
      };
    default:
      return state;
  }
};
