import { Route } from 'react-router';
import { AuthRouter } from 'PayMyBill/routers/AuthRouter';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'PayMyBill/core/selectors';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <AuthRouter {...props} />
      }
    />
  );
};
