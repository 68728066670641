import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectVenueIds } from 'PayMyBill/venues/selectors';
import { VenueItem } from 'PayMyBill/venues/components';

export const VenuesListPage: React.FC = () => {
  const venues = useSelector(selectVenueIds);

  return (
    <>
      {venues.length > 0 ? (
        venues.map((v) => <VenueItem venueId={v} key={`venue-${v}`} />)
      ) : (
        <Alert bsStyle="danger">No venues found</Alert>
      )}
    </>
  );
};
