import { initialState } from 'PayMyBill/checkout/model';
import {
  SET_INITIALIZING,
  SET_INITIALIZED,
  SET_PAYMENT_PROCESSING,
  SET_PAYMENT_COMPLETE,
  SET_PAYMENT_FAILED,
  SET_GUEST_EMAIL_ADDRESS,
  SET_CAN_MAKE_PAYMENT,
  RESET_BRAINTREE,
} from 'PayMyBill/checkout/actionTypes';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIALIZING:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          initializing: true,
          clientToken: null,
          initialized: false,
          processing: false,
        },
      };
    case SET_INITIALIZED:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          initializing: false,
          clientToken: action.token,
          initialized: true,
        },
      };
    case SET_PAYMENT_PROCESSING:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          processing: true,
          complete: false,
          failed: false,
          errorMessage: null,
        },
      };
    case SET_PAYMENT_COMPLETE:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          processing: false,
          complete: true,
        },
      };
    case SET_PAYMENT_FAILED:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          processing: false,
          complete: false,
          failed: true,
          errorMessage: action.errorMessage,
        },
        canMakePayment: false,
      };
    case SET_GUEST_EMAIL_ADDRESS:
      return {
        ...state,
        guestEmailAddress: action.guestEmailAddress,
      };
    case SET_CAN_MAKE_PAYMENT:
      return {
        ...state,
        canMakePayment: action.canMakePayment,
      };
    case RESET_BRAINTREE:
      return {
        ...state,
        braintree: {
          ...state.braintree,
          initializing: true,
          clientToken: null,
          initialized: false,
          processing: false,
          failed: false,
          errorMessage: null,
        },
      };
    default:
      return state;
  }
};

export const selectBraintree = (state) => {
  return state.payMyBill.checkout.braintree;
};
