export const BillItemSubtext = ({ item }) => {
  const hasPortion = Boolean(item.portion);
  const hasChoiceLines = Boolean(item.choiceLines);
  const list = [];

  hasPortion && item.portion.name && list.push(item.portion.name);

  const printChoiceLines = (item) => {
    item.choiceLines.forEach((line) => {
      list.push(line.display.name);

      if (line.choiceLines) {
        printChoiceLines(line);
      }
    });
  };

  hasChoiceLines && printChoiceLines(item);

  return <>{list.join(', ')}</>;
};
