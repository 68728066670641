import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { LoginPage } from 'login';
import ResetPassword from 'user/components/PasswordReset/ResetPassword';
import { PasswordRequest } from 'user/components/PasswordReset';
import Register from 'user/components/Registration/Register';
import { LoggedInUserRouter } from 'routers/User';
import { OrderingRoute } from 'routers/OrderingRoute';
import { useConfig } from 'contexts/ConfigContext';
import { useVenues } from 'contexts/VenueContext';

export const UserRouter: React.FC = () => {
  const { path } = useRouteMatch();
  const { disableUserSignIn } = useConfig();
  const { selectedVenue } = useVenues();

  if (disableUserSignIn) {
    const redirectLink = selectedVenue
      ? `/venue/${selectedVenue.id}`
      : '/venues';
    return <Redirect to={redirectLink} />;
  }

  return (
    <Switch>
      <Route path={`${path}/login`} component={LoginPage} />
      <Route path={`${path}/resetPassword(.+)`} component={ResetPassword} />
      <Route path={`${path}/password-request`} component={PasswordRequest} />
      <OrderingRoute path={`${path}/register`} component={Register} />
      <Route component={LoggedInUserRouter} />
    </Switch>
  );
};
