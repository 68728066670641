import {
  BillItemTitle,
  BillItemSubtext,
} from 'PayMyBill/bill/components/billDisplay';

export const BillTableItem = ({ item }) => {
  return (
    <div>
      <BillItemTitle item={item} />
      <div>
        <small>
          <BillItemSubtext item={item} />
        </small>
      </div>
    </div>
  );
};
