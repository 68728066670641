import { Route, Switch } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { App } from 'App';
import { PayMyBillRouter } from 'PayMyBill/routers';
import { LegacyRouter } from 'routers/Legacy';
import { LoyaltyRouter } from 'routers/Loyalty';
import { UserRouter } from 'routers/User';
import { FeatureFlagPage } from 'featureflags';
import { useConfig } from 'contexts/ConfigContext';
import { useDispatch } from 'react-redux';
import { checkForSso } from 'sso/actions';
import { NotFound } from 'routers';

export const Routes: React.FC = () => {
  const {
    supportsRewardsRedemption,
    env,
    iosAppId,
    pageTitle,
    accountDeletionOnly,
  } = useConfig();
  const dispatch = useDispatch();

  // This checks for SSO on initial load
  dispatch(checkForSso());

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {iosAppId ? (
          <meta name="apple-itunes-app" content={`app-id=${iosAppId}`} />
        ) : null}
      </Helmet>
      <Switch>
        {!accountDeletionOnly ? (
          <Route path="/pay-my-bill" component={PayMyBillRouter} />
        ) : null}
        <App>
          {env === 'qa1' || env === 'qa2' || env === 'development' ? (
            <Route path="/ff" component={FeatureFlagPage} />
          ) : null}
          <Route path="/user" component={UserRouter} />
          {supportsRewardsRedemption &&
          supportsRewardsRedemption === true &&
          !accountDeletionOnly ? (
            <Route path="/loyalty" component={LoyaltyRouter} />
          ) : null}
          <Route component={LegacyRouter} />
        </App>

        <Route component={NotFound} />
      </Switch>
    </>
  );
};
