export const BillItemTitle = ({ item }) => {
  const hasQuantity = Boolean(item.quantity);

  return (
    <>
      {hasQuantity
        ? `${item.quantity} x ${item.display.name}`
        : `${item.display.name}`}
    </>
  );
};
