import { Switch, Route, Redirect } from 'react-router';

import { GuestCheckoutPage } from 'PayMyBill/login';
import { RouteComponentProps } from 'react-router-dom';

export const AuthRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/guest-checkout`}
      component={GuestCheckoutPage}
    />
    <Route render={() => <Redirect to="/pay-my-bill" />} />
  </Switch>
);
