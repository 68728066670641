import { Field } from 'redux-form/immutable';
import { Col } from 'react-bootstrap';
import { SelectFieldGroup } from '../FormComponents';
import { Field as FieldType } from 'types/models/Forms';

interface SelectProps {
  field: FieldType;
}

/**
 * @deprecated
 */
export const Select: React.FC<SelectProps> = ({ field }) => (
  <Col sm={field.width}>
    <Field
      name={field.name}
      type="select"
      component={SelectFieldGroup}
      label={field.label}
    >
      {field.options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Field>
  </Col>
);
