import Fuse from 'fuse.js';
import _sortBy from 'lodash/sortBy';
import { Map, List } from 'immutable';

export const initialState = {
  fetch: {
    isFetching: false,
    error: false,
    payload: null,
  },
  entities: {
    venues: Map(),
    services: Map(),
  },
  results: List(),
  filterTerm: null,
  serviceID: null,
};

export const normalizeByKey = (data, key) =>
  data.reduce((a, c) => ({ ...a, [c[key]]: c }), {});

export const normalizeData = (json) => {
  return {
    venues: normalizeByKey(json.venues, 'id'),
    services: normalizeByKey(json.services, 'id'),
  };
};

export const getServiceByName = (services, name) =>
  services.find((v) => v.get('name') === name, null, false);

export const filterByServiceId = (venues, serviceId) =>
  venues.filter((venue) => venue.services.some((sId) => sId === serviceId));

export const filterByTerm = (list, term, keys) => {
  const fuse = new Fuse(list, {
    keys,
    minMatchCharLength: 3,
    threshold: 0.2,
    caseSensitive: false,
  });

  return fuse.search(term);
};

export const getVenueIds = (venues) => venues.map((v) => v.id);

export const filterVenues = (state) => {
  // Convert to an immutable lsit first, the to JS
  let venues = state.entities.venues.toList().toJS();

  // Filter by set service ID
  if (!state.serviceID) {
    return List();
  }

  venues = filterByServiceId(venues, state.serviceID);

  venues = venues.filter((venue) => venue.canOrder);

  // If the term is set, apply the filter
  if (state.filterTerm && state.filterTerm.length > 2) {
    venues = filterByTerm(venues, state.filterTerm, [
      'name',
      'address.line1',
      'address.line2',
      'address.line3',
      'address.town',
      'address.county',
      'address.postcode',
    ]);
    venues.forEach((venue, index) => {
      venues[index] = venue.item;
    });
  }

  // Sort by distance then name. Distance checks for values of 0 or greater to filter out results without distance being set.
  venues = _sortBy(
    venues,
    [(v) => (v.distance > -1 ? v.distance : null), 'name'],
    ['asc'],
  );

  // Trim, only display first 10 results
  venues = venues.slice(0, 9);
  // Convert back to immutable List
  return List(getVenueIds(venues));
};
