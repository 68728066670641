import { ListGroup } from 'react-bootstrap';
import { TableListItem } from 'PayMyBill/table/components';
import { AccountSummary } from 'types/models/PayMyBill';

interface TableListProps {
  accounts: AccountSummary[];
  path: string;
}

export const TableList: React.FC<TableListProps> = ({ accounts, path }) => {
  return accounts && accounts.length > 0 ? (
    <ListGroup>
      {accounts &&
        accounts.map((v) => (
          <TableListItem key={v.AccountId} account={v} path={path} />
        ))}
    </ListGroup>
  ) : (
    <h3>No Tables Found</h3>
  );
};
