import { BillTableItem } from 'PayMyBill/bill/components/billDisplay';
import { calculateItemCost } from 'PayMyBill/utils';
import { FormattedCurrency } from 'common/components/FormattedCurrency';

export const BillTableProductRow = ({
  data,
  reduction,
  promotion,
  additionalClasses = '',
}) => {
  const value = calculateItemCost(data);

  if (!data.portion) {
    return null;
  }

  return (
    <div className={`bill-table-row ${additionalClasses}`}>
      <BillTableItem item={data} />
      <div className="text-right">
        <FormattedCurrency value={reduction || promotion ? -value : value} />
      </div>
    </div>
  );
};
