import {
  AztecPortion,
  AztecProduct,
  AztecProducts,
  BreadcrumbTrack,
  ChoiceGroups,
  DisplayRecord,
} from 'types/models';
import {
  isChoiceInvalid,
  getProductPortions,
  getSelectedPortion,
  portionsHaveChoices,
  portionHasChoices,
  getChildPortionId,
  doesPortionHaveValidPrice,
  getChoiceGroupChoices,
} from 'menu/utils';
import { cloneDeep } from 'lodash';

export const isProductInvalid = (
  product: AztecProduct,
  breadcrumbs: BreadcrumbTrack[],
): boolean => {
  const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
  const productPortions = getProductPortions(product);
  const selectedPortion = lastBreadcrumb
    ? product.portions[
        getChildPortionId(
          lastBreadcrumb.portionRatios,
          product.id,
          lastBreadcrumb.portionId,
        )
      ]
    : getSelectedPortion(product);

  // No portion selected
  if (!selectedPortion) return true;

  // For single and multiple portion selector - must have a portion selected
  if (!portionsHaveChoices(product)) {
    return productPortions.every(
      (p) => !p.hasOwnProperty('quantity') || p.quantity === 0,
    );
  }

  const selectedPortionHasChoices = portionHasChoices(selectedPortion);

  // Choice min/max requirements are met
  if (selectedPortionHasChoices) {
    return selectedPortion.choices.some((c) => {
      return isChoiceInvalid(c);
    });
  }

  return false;
};

export const getNestedProduct = (
  product: AztecProduct,
  breadcrumbs: BreadcrumbTrack[],
): AztecProduct | undefined => {
  let portionToUse = getSelectedPortion(product);
  let productToUse: AztecProduct | undefined;
  let lastProduct: AztecProduct | undefined;
  breadcrumbs.forEach((bc, idx) => {
    if (idx > 0 && productToUse) {
      const prevBreadcrumb = breadcrumbs[idx - 1];
      const portionIdToUse = getChildPortionId(
        prevBreadcrumb.portionRatios,
        productToUse.id,
        prevBreadcrumb.portionId,
      );
      portionToUse = productToUse.portions[portionIdToUse];
    }

    const selectedChoice = portionToUse.choices[bc.choiceIndex];
    const selectedChoiceGroupChoice =
      selectedChoice.choiceGroup?.choices[bc.choiceGroupIndex];
    if (selectedChoiceGroupChoice.productToUse) {
      // Return the product if it is the last breadcrumb item
      // else assign the product to be looped over again
      if (breadcrumbs.length === idx + 1) {
        lastProduct = selectedChoiceGroupChoice.productToUse;
      } else {
        productToUse = selectedChoiceGroupChoice.productToUse;
      }
    } else return;
  });

  return lastProduct;
};

export const getAztecProduct = (
  productId: number,
  productList: AztecProducts,
): AztecProduct => {
  return productList[productId];
};

export const isProductOutOfStock = (product: AztecProduct): boolean => {
  return Boolean(product?.isOutOfStock);
};

export const isPortionAvailable = (
  portion: AztecPortion,
  choiceGroups: ChoiceGroups,
  productList: AztecProducts,
): boolean => {
  if (!doesPortionHaveValidPrice(portion)) {
    return false;
  }

  // If portion has no choices, it's always valid
  if (!portionHasChoices(portion)) {
    return true;
  }

  // Loop through choices and generate choice data
  for (const choice of portion.choices) {
    const choiceGroup = cloneDeep(choiceGroups[choice.choiceId]);

    if (choiceGroup?.choices && choiceGroup.choices.length > 0) {
      getChoiceGroupChoices(
        choiceGroup.choices,
        choice.displayRecordId,
        productList,
        choiceGroups,
      );

      // Check that at least one choice has a valid portion
      const atLeastOneChoiceAvailable = choiceGroup.choices.some((cgc) => {
        const portionIdToUse = getChildPortionId(
          choiceGroup.portionRatios,
          cgc.choiceId ?? cgc.productId,
          choice.portionId,
        );
        const portionToUse = cgc.productToUse?.portions[portionIdToUse];
        return !!portionToUse;
      });

      // Break the loop as one choice is available
      if (atLeastOneChoiceAvailable) {
        return true;
      }
    }
  }

  // Portion or choice is not valid
  return false;
};

export const isProductAvailable = (
  product: AztecProduct,
  choiceGroups: ChoiceGroups,
  productList: AztecProducts,
): boolean => {
  const productPortions = getProductPortions(product);
  // Check at least 1 portion has a price
  const availablePortions = productPortions.filter((portion) =>
    doesPortionHaveValidPrice(portion),
  );
  if (availablePortions.length === 0) return false;

  // Do any portions have choices
  if (availablePortions.some((p) => portionHasChoices(p))) {
    // Check that at least one portion and choices are valid
    return availablePortions.some((portion) =>
      isPortionAvailable(portion, choiceGroups, productList),
    );
  }

  return true;
};

export const getProductDisplayRecord = (
  product: AztecProduct,
  displayRecordId: number,
): DisplayRecord => {
  return product.displayRecords[displayRecordId];
};
