import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field } from 'redux-form/immutable';
import { FieldGroup } from '../FormComponents';
import { min, max, required, loyaltyCard } from 'core/validation';
import { Field as FieldType } from 'types/models/Forms';

interface CardNumberProps {
  field: FieldType;
}

/**
 * @deprecated
 */
export const CardNumber: React.FC<CardNumberProps> = ({ field }) => {
  const [validation, setValidation] = useState<
    ((value: string) => string | undefined)[]
  >([]);

  const normalizeNumbersOnly = (value: string) => {
    if (!value) {
      return value;
    }

    return value.replace(/[^\d]/g, '');
  };

  useEffect(() => {
    const fieldMin = min(field.min);
    const fieldMax = max(field.max);
    const fieldLoyaltyCard = loyaltyCard(true);
    const fieldRequired = required(field.required);

    const tmpValidation = [fieldMin, fieldMax, fieldLoyaltyCard, fieldRequired];

    setValidation(tmpValidation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width || 12}>
      <Field
        name={field.name}
        component={FieldGroup}
        type="text"
        // inputMode="numeric" -- this doesn't exist in TS props for some reason
        pattern="[0-9]*"
        normalize={normalizeNumbersOnly}
        placeholder={field.placeholder}
        label={field.label}
        validate={validation}
      />
    </Col>
  );
};
