import { fetchBodyFormatter, setupFetchParams } from 'utils';
import {
  FETCH_REQUESTED,
  FETCH_COMPLETE,
  MERGE_VENUES,
  MERGE_SERVICES,
  SET_RESULT_IDS,
  SET_SERVICE_ID,
  SET_FILTER,
} from './actionTypes';
import { filterVenues, normalizeData, getServiceByName } from './model';
import { addNotification } from 'core/actions';

export const setFilteredVenues = (dispatch, getState) => {
  const results = filterVenues(getState().payMyBill.venues);

  dispatch({
    type: SET_RESULT_IDS,
    results,
  });
};

export const setFilter = (filterTerm) => {
  return (dispatch) => {
    dispatch({
      type: SET_FILTER,
      filterTerm,
    });

    dispatch(setFilteredVenues);
  };
};

const fetchVenues = (dispatch, getState) => {
  dispatch({
    type: FETCH_REQUESTED,
  });

  const requestBody = {
    method: 'getVenueSummary',
  };

  const fetchParams = setupFetchParams();

  fetch(fetchParams.url, {
    method: 'POST',
    headers: {
      ...fetchParams.headers,
    },
    body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
  })
    .then((res) => res.ok && res.json())
    .then((json) => {
      dispatch({
        type: FETCH_COMPLETE,
        payload: json,
      });

      const normalized = normalizeData(json);

      dispatch({
        type: MERGE_SERVICES,
        services: normalized.services,
      });

      dispatch({
        type: MERGE_VENUES,
        venues: normalized.venues,
      });

      const service = getServiceByName(
        getState().payMyBill.venues.entities.services,
        'Pay My Bill',
      );

      dispatch({
        type: SET_SERVICE_ID,
        serviceID: service?.get('id'),
      });

      dispatch(setFilteredVenues);
    });
};

const shouldFetchVenues = (state) => {
  return !state.payMyBill.venues.fetch.payload;
};

export const fetchVenuesIfNeeded = () => (dispatch, getState) => {
  if (shouldFetchVenues(getState())) {
    return dispatch(fetchVenues);
  }

  return false;
};

export const fetchVenuesByLocation = () => (dispatch) => {
  if (!navigator.geolocation) {
    // eslint-disable-next-line no-alert
    dispatch(
      addNotification(
        'Unfortunately, we were unable to determine your location as it is not supported by your web browser.',
      ),
    );

    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const requestBody = {
        method: 'getVenueSummary',
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        maxResults: 10,
      };

      const fetchParams = setupFetchParams();

      fetch(fetchParams.url, {
        method: 'POST',
        headers: {
          ...fetchParams.headers,
        },
        body: fetchBodyFormatter({ ...fetchParams.body, ...requestBody }),
      })
        .then((res) => res.ok && res.json())
        .then((json) => {
          const normalized = normalizeData(json);

          dispatch({
            type: MERGE_VENUES,
            venues: normalized.venues,
          });

          dispatch(setFilteredVenues);
        });
    },
    (err) => {
      //let's have some specific, friendlier errors
      // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError/code
      switch (err.code) {
        case 1:
          dispatch(
            addNotification(
              'Unfortunately, we were unable to determine your location as you have chosen to decline the permission.',
              'danger',
            ),
          );
          break;
        case 2:
          dispatch(
            addNotification(
              'Unfortunately, we were unable to determine your location.',
              'danger',
            ),
          );
          break;
        case 3:
          dispatch(
            addNotification(
              'Unfortunately, the request to determine your location has timed out. Please try again.',
              'danger',
            ),
          );
          break;
      }
    },
  );
};
