import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { useConfig, usePhrases } from 'contexts/ConfigContext';

export const Header: React.FC = () => {
  const {
    logoLinkToHomepage,
    homeText,
    homepage: externalHome,
    removeBackLink,
    hideSelectVenueLink,
  } = useConfig();
  const { selectVenue } = usePhrases();
  return (
    <Navbar className="header">
      <Navbar.Header>
        <div className="logo">
          {logoLinkToHomepage ? (
            <div>
              <a href={externalHome} target="blank">
                <div className="logo-img" />
              </a>
            </div>
          ) : (
            <Link to="/pay-my-bill">
              <div className="logo-img" />
            </Link>
          )}
        </div>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav>
          {!removeBackLink && (
            <li role="presentation" key="backLink">
              <a href={externalHome} className="header-link">
                <MdArrowBack className="react-icon" /> {homeText || 'Home'}
              </a>
            </li>
          )}
          {!hideSelectVenueLink && (
            <li role="presentation" key="selectButton">
              <Link
                to="/pay-my-bill"
                className="header-link header-link-left-padding"
              >
                {selectVenue}
              </Link>
            </li>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
