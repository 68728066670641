import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { VenuesListPage, VenuesMapPage } from 'PayMyBill/venues';

export const VenuesRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/list`} component={VenuesListPage} />
      <Route path={`${match.path}/map`} component={VenuesMapPage} />
      <Route render={() => <Redirect to={`${match.path}/list`} />} />
    </Switch>
  );
};
