import { Panel, Image, Alert } from 'react-bootstrap';

import { DefaultPageLayout } from 'PayMyBill/core/layouts';
import { FixMeLater } from 'types/FixMeLater';

interface DefaultLayoutProps {
  title?: string;
  children?: React.ReactNode;
  bannerImage?: string;
  headerCopy?: string;
  error?: FixMeLater;
}

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  title,
  children,
  bannerImage,
  headerCopy,
  error,
}) => (
  <DefaultPageLayout>
    {bannerImage && <Image src={bannerImage} responsive rounded />}
    {headerCopy && <p>{headerCopy}</p>}
    {error && <Alert bsStyle="danger">{error}</Alert>}
    <Panel>
      <Panel.Body>
        <h2>{title}</h2>
        {children}
      </Panel.Body>
    </Panel>
  </DefaultPageLayout>
);
