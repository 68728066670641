import { RootStateOrAny } from 'react-redux';
import { Bill } from 'types/models/PayMyBill';

export const selectIsBillLoading = (state: RootStateOrAny): boolean => {
  return (
    state.payMyBill.bill.fetch.response === null ||
    state.payMyBill.bill.fetch.loading
  );
};

export const selectIsPayable = (state: RootStateOrAny): boolean => {
  return (
    state.payMyBill.bill.data &&
    state.payMyBill.bill.data.outstandingBalanceToPay > 0
  );
};

export const selectBill = (state: RootStateOrAny): Bill => {
  return state.payMyBill.bill.data;
};

export const selectHasTip = (state: RootStateOrAny): boolean => {
  return state.payMyBill.bill.tipAmount > 0;
};

export const selectTipAmount = (state: RootStateOrAny): number => {
  return state.payMyBill.bill.tipAmount;
};

export const selectTableNumber = (state: RootStateOrAny): number => {
  return state.payMyBill.bill.tableNumber;
};

export const selectHasBill = (state: RootStateOrAny): boolean => {
  return Boolean(state.payMyBill.bill.data);
};
