import { initialState } from 'PayMyBill/table/model';
import {
  RECEIVE_ACCOUNTS,
  REQUEST_ACCOUNTS,
  RESET_SEARCH_FLAG,
  SET_ACCOUNT_SEARCH_ERROR,
} from 'PayMyBill/table/actionTypes';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACCOUNTS:
      return {
        ...state,
        blockRedirect: false,
        accounts: null,
        fetch: {
          ...state.fetch,
          loading: true,
          response: null,
        },
        fetched: false,
      };
    case RECEIVE_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
        fetch: {
          ...state.fetch,
          loading: false,
          response: action.response,
        },
        fetched: true,
      };
    case RESET_SEARCH_FLAG:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          error: null,
        },
        fetched: false,
      };
    case SET_ACCOUNT_SEARCH_ERROR:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          loading: false,
          error: true,
        },
        fetched: true,
      };
    default:
      return state;
  }
};

export const selectAccountError = (state) =>
  state?.payMyBill?.table?.fetch?.error;
