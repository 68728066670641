import { fetchBodyFormatter, setupFetchParams } from 'utils';
import { addNotification } from 'core/actions';
import {
  RECEIVE_BILL,
  ADD_TIP,
  RESET_BILL,
  REQUEST_BILL,
} from 'PayMyBill/bill/actionTypes';

export const resetBill = () => ({
  type: RESET_BILL,
});

const requestBill = () => ({
  type: REQUEST_BILL,
});

export const receiveBill = (json) => ({
  type: RECEIVE_BILL,
  response: json,
});

export const setTipAmount = (value) => ({
  type: ADD_TIP,
  tipAmount: value,
});

export const redirectToBillReview = (history, match) => {
  if (!match) {
    return;
  }

  const {
    params: { venueId, areaId, accountId },
  } = match;
  history.push(`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}/`);
};

const shouldRedirectToBillReview = (state) =>
  !state.payMyBill.bill.fetch.response;

export const redirectToBillReviewIfNeeded =
  (history, match) => (dispatch, getState) => {
    shouldRedirectToBillReview(getState()) &&
      redirectToBillReview(history, match);
  };

const shouldFetchBill = (state) => !state.payMyBill.bill.fetch.response;

const fetchBill = (venueId, areaId, accountId) => (dispatch) => {
  const fetchParams = setupFetchParams();

  dispatch(requestBill);

  fetch(fetchParams.url, {
    method: 'POST',
    headers: {
      ...fetchParams.headers,
    },
    body: fetchBodyFormatter({
      ...fetchParams.body,
      method: 'getAccount',
      siteId: venueId,
      salesAreaId: areaId,
      accountId,
    }),
  })
    .then((res) => res.ok && res.json())
    .then((json) => {
      if (json.response !== 'OK') {
        dispatch(
          addNotification(response.data.detail, 'danger', response.data.code),
        );
      } else {
        dispatch(receiveBill(json));
      }
    })
    .catch();
};

export const fetchBillIfNeeded =
  (venueId, areaId, accountId) => (dispatch, getState) => {
    shouldFetchBill(getState()) &&
      dispatch(fetchBill(venueId, areaId, accountId));
  };
