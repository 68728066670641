import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { TableRouter } from 'PayMyBill/routers';
import { SalesAreasPage } from 'PayMyBill/sales-areas';

export const VenueRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      path={`${match.path}/:venueId(\\d+)/:areaId(\\d+)`}
      component={TableRouter}
    />
    <Route path={`${match.path}/:venueId(\\d+)`} component={SalesAreasPage} />
    <Route render={() => <Redirect to={`${match.url}s`} />} />
  </Switch>
);
