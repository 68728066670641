import { handleChoiceLines } from 'PayMyBill/utils';

export const calculateItemCost = (item) => {
  let value = item.tariffPrice || item.total || 0;

  if ('choiceLines' in item) {
    value += handleChoiceLines(item);
  }

  return Number(value);
};
