import { RootStateOrAny } from 'react-redux';
import { AccountSummary } from 'types/models/PayMyBill';

export const selectTableNumber = (state: RootStateOrAny): number => {
  return state.payMyBill?.table?.tableNumber;
};

export const selectAccountLoading = (state: RootStateOrAny): boolean => {
  return state.payMyBill?.table?.fetch?.loading;
};

export const selectHasSearched = (state: RootStateOrAny): boolean => {
  return state.payMyBill?.table?.fetched;
};

export const selectBlockRedirect = (state: RootStateOrAny): boolean => {
  return state.payMyBill?.table?.blockRedirect;
};

export const selectAccounts = (state: RootStateOrAny): AccountSummary[] => {
  return state?.payMyBill?.table?.accounts;
};
