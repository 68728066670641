import { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { Field as ImmutableField } from 'redux-form/immutable';
import { FieldGroup } from '../FormComponents';
import { confirmPassword } from 'core/model';
import { Field } from 'types/models/Forms';

interface ConfirmPasswordProps {
  field: Field;
}

/**
 * @deprecated
 */
export const ConfirmPassword: React.FC<ConfirmPasswordProps> = ({ field }) => {
  const [validation, setValidation] =
    useState<(value: never, allValues: never) => string | undefined>();

  useEffect(() => {
    const fieldConfirmPassword = confirmPassword('password');

    setValidation(fieldConfirmPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col sm={field.width}>
      <ImmutableField
        name={field.name}
        component={FieldGroup}
        type="password"
        placeholder={field.placeholder}
        label={field.label}
        validate={validation}
      />
    </Col>
  );
};
