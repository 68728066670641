import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';

import { ReviewBillPage } from 'PayMyBill/bill';
import { AddTipPage } from 'PayMyBill/bill/components/tip';
import { PayPage } from 'PayMyBill/checkout';
import { ProtectedRoute } from 'PayMyBill/routers';
import { GuestCheckoutPage } from 'PayMyBill/login';
import { CompletePage } from 'PayMyBill/complete';

export const PaymentRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route exact path={`${match.path}`} component={ReviewBillPage} />
    <Route exact path={`${match.path}/add-tip`} component={AddTipPage} />
    <Route
      exact
      path={`${match.path}/guest-checkout`}
      component={GuestCheckoutPage}
    />
    <ProtectedRoute exact path={`${match.path}/pay`} component={PayPage} />
    <Route exact path={`${match.path}/complete`} component={CompletePage} />
    <Route render={() => <Redirect to={`${match.url}`} />} />
  </Switch>
);
