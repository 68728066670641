/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { Component, useEffect } from 'react';
import { Button, Form, FormGroup, Grid, Panel } from 'react-bootstrap';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormGenerator } from 'core/components/OldForm';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from 'core/actions';
import { useConfig, usePhrases } from 'contexts/ConfigContext';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  selectIsFetchingRegister,
  selectIsLoggedIn,
  selectIsRegisteringFromCheckout,
} from 'user/selectors';
import { selectDefaultBirthday } from 'user/reducer';
import axios from 'axios';
import { register as registerAction, registerFromCheckout } from 'user/actions';
import { Map } from 'immutable';
import { useServices, useVenues } from 'contexts/VenueContext';
import { useCheckout } from 'contexts/CheckoutContext';

let captcha;

class Captcha extends Component {
  render() {
    const {
      input: { onChange },
      sitekey,
    } = this.props;

    return (
      <ReCAPTCHA
        ref={(el) => {
          captcha = el;
        }}
        size="invisible"
        sitekey={sitekey}
        onChange={onChange}
      />
    );
  }
}

const Register = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const { captchaDisabled, devCaptchaSitekey, forms, prodCaptchaSitekey } =
    useConfig();

  const { selectedVenue } = useVenues();
  const { selectedService } = useServices();
  const { checkBasket, isFetchingBasket, setIsFetchingBasket } = useCheckout();

  const history = useHistory();

  const {
    login: { createAccountText },
  } = usePhrases();

  useEffect(() => {
    dispatch(removeNotification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateBirthdayState = (defaultBirthday) => {
    if (!defaultBirthday) {
      return Map({ day: '01', month: '01', year: '1930' });
    }

    return Map({
      day: defaultBirthday.get('day') || '01',
      month: defaultBirthday.get('month') || '01',
      year: defaultBirthday.get('year') || '1930',
    });
  };

  const normalise = (user, defaultBirthday) => {
    let birthday = validateBirthdayState(defaultBirthday);
    let userObject = user.map((item) => (item === true ? 1 : item));

    const normDate = (type) =>
      birthday.get(type).length > 1
        ? birthday.get(type)
        : `0${birthday.get(type)}`;

    birthday = `${birthday.get('year')}-${normDate('month')}-${normDate(
      'day',
    )}`;

    if (!userObject.get('dob')) {
      userObject = userObject.set('dob', birthday);
    }

    if (!userObject.get('title')) {
      userObject = userObject.set('title', 'Mr');
    }

    if (!userObject.get('extra2')) {
      userObject = userObject.set('extra2', 0);
    }

    if (!userObject.get('email_optin')) {
      userObject = userObject.set('email_optin', 0);
    }

    return userObject;
  };

  const handleRegister = (
    user,
    captcha,
    captchaDisabled,
    isRegisteringFromCheckout,
    defaultBirthday,
  ) => {
    if (captchaDisabled) {
      dispatch(
        registerAction(
          normalise(user, defaultBirthday),
          history,
          selectedVenue?.id,
          selectedService,
          isRegisteringFromCheckout ? setIsFetchingBasket : false,
          checkBasket,
        ),
      );
    }

    if (captcha && captcha.getValue() && !captchaDisabled) {
      axios
        .post(`/recaptcha?response=${captcha.getValue()}`)
        .then((response) => {
          if (response.data.success) {
            dispatch(
              registerAction(
                normalise(user, defaultBirthday),
                history,
                selectedVenue?.id,
                selectedService,
                isRegisteringFromCheckout ? setIsFetchingBasket : false,
                checkBasket
              ),
            );
          }
        });
    }

    captcha.reset();
  };

  const location = useLocation();

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const defaultBirthday = validateBirthdayState(
    useSelector(selectDefaultBirthday),
  );

  const isFetchingRegister = useSelector(selectIsFetchingRegister);
  const isRegisteringFromCheckout = useSelector(
    selectIsRegisteringFromCheckout,
  );

  const { from } = location.state || { from: { pathname: '/user/order' } };

  useEffect(() => {
    if (from === '/checkout' || from?.pathname === '/checkout') {
      dispatch(registerFromCheckout(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn && !isFetchingBasket) {
    return <Redirect to={from} />;
  }

  return (
    <Grid>
      <p className="text-right">
        <Link to="/user/login">Already have an account? Log in here</Link>
      </p>
      <Panel>
        <h2>We Just Need A Few Details</h2>
        <Form
          data-testid="registration-form"
          onSubmit={handleSubmit(() => captcha.execute())}
        >
          <Field
            name="captcha"
            component={Captcha}
            sitekey={
              prodCaptchaSitekey ||
              devCaptchaSitekey ||
              '6LdqtnoUAAAAAPuSlZ2wVCCtmQGYNd-PvSfaFVQw'
            }
            onChange={handleSubmit((user) =>
              handleRegister(
                user,
                captcha,
                captchaDisabled,
                isRegisteringFromCheckout,
                defaultBirthday,
              ),
            )}
          />
          {forms?.register && <FormGenerator definition={forms.register} />}
          <FormGroup>
            {isFetchingRegister || isFetchingBasket ? (
              <Button
                bsStyle="primary"
                className="btn-pe-submit"
                type="submit"
                disabled
              >
                Loading
              </Button>
            ) : (
              <Button
                bsStyle="primary"
                className="btn-pe-submit"
                type="submit"
                onClick={handleSubmit(() => captcha.execute())}
                data-testid="register-create-account-button"
              >
                {createAccountText}
              </Button>
            )}
          </FormGroup>
        </Form>
      </Panel>
    </Grid>
  );
};

export default reduxForm({ form: 'register' })(Register);
