import { AztecChoice, Choice } from 'types/models';
import { getSelectedChoices } from 'menu/utils';
import { BasketChoiceLine } from './BasketChoiceLine';
import { Fragment } from 'react';

interface BasketNestedChoiceLineProps {
  choice: AztecChoice | Choice;
}

export const BasketNestedChoiceLine: React.FC<BasketNestedChoiceLineProps> = ({
  choice,
}) => {
  if (!choice.choiceGroup) return null;
  const selectedChoices = getSelectedChoices(choice.choiceGroup.choices);

  return (
    <>
      {selectedChoices.map((sc) => (
        <Fragment
          key={`${sc.choiceId ? sc.choiceId : sc.productId}-${
            sc.displayRecordToUse?.id
          }`}
        >
          {' ('}
          <BasketChoiceLine
            choice={sc}
            key={`${sc.choiceId ? sc.choiceId : sc.productId}`}
          />
          {')'}
        </Fragment>
      ))}
    </>
  );
};
