import { useEffect } from 'react';
import { Button, Col, Form, FormGroup, Panel } from 'react-bootstrap';
import { reduxForm } from 'redux-form/immutable';
import { Sidebar } from '.';
import { FormGenerator } from 'core/components/OldForm';
import { useDispatch, useSelector, connect } from 'react-redux';
import { removeNotification } from 'core/actions';
import { useConfig } from 'contexts/ConfigContext';
import { selectDefaultBirthday } from 'user/reducer';
import { selectIsFetchingEdit } from 'user/selectors';
import { edit } from 'user/actions';
import { Map } from 'immutable';

let Edit = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { forms } = useConfig();

  const validateBirthdayState = (defaultBirthday) => {
    if (!defaultBirthday) {
      return Map({ day: '01', month: '01', year: '1930' });
    }

    return Map({
      day: defaultBirthday.get('day') || '01',
      month: defaultBirthday.get('month') || '01',
      year: defaultBirthday.get('year') || '1930',
    });
  };

  const normalise = (user, defaultBirthday) => {
    let birthday = validateBirthdayState(defaultBirthday);
    let userObject = user.map((item) => (item === true ? 1 : item));

    const normDate = (type) =>
      birthday.get(type).length > 1
        ? birthday.get(type)
        : `0${birthday.get(type)}`;

    birthday = `${birthday.get('year')}-${normDate('month')}-${normDate(
      'day',
    )}`;

    userObject = userObject.set('dob', birthday);

    return userObject;
  };

  const isFetchingEdit = useSelector(selectIsFetchingEdit);
  const defaultBirthday = useSelector(selectDefaultBirthday);

  const handleEdit = (user, defaultBirthday) => {
    dispatch(edit(normalise(user, defaultBirthday)));
  };

  useEffect(() => {
    dispatch(removeNotification());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let button;

  if (isFetchingEdit) {
    button = (
      <Button bsStyle="primary" type="submit" disabled>
        Loading
      </Button>
    );
  } else {
    button = (
      <Button
        bsStyle="primary"
        type="submit"
        onClick={handleSubmit((user) => handleEdit(user, defaultBirthday))}
      >
        Update Details
      </Button>
    );
  }

  return (
    <div>
      <Sidebar />
      <Col sm={9}>
        <Panel>
          <h2>My Details</h2>

          <Form
            data-testid="my-details-form"
            onSubmit={handleSubmit((user) => handleEdit(user, defaultBirthday))}
          >
            {forms && forms.edit && <FormGenerator definition={forms.edit} />}
            <FormGroup>{button}</FormGroup>
          </Form>
        </Panel>
      </Col>
    </div>
  );
};

Edit = reduxForm({ form: 'edit' })(Edit);

Edit = connect((state) => ({ initialValues: state.user.get('user').toJS() }))(
  Edit,
);

export default Edit;
