import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { VenueRouter } from 'PayMyBill/routers';
import { VenuesPage } from 'PayMyBill/venues';

/**
 * Pay my Bill service router.
 *
 * @param {object} match - provided by `react-router-dom`
 */
export const PayMyBillRouter: React.FC<RouteComponentProps> = ({ match }) => (
  <>
    <Helmet>
      <title>Pay My Bill</title>
    </Helmet>
    <Switch>
      <Route path={`${match.path}/venues`} component={VenuesPage} />
      <Route path={`${match.path}/venue`} component={VenueRouter} />
      <Route render={() => <Redirect to={`${match.url}/venues`} />} />
    </Switch>
  </>
);
