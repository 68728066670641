import { RootStateOrAny } from 'react-redux';
import { VenueSummary } from 'types/models';

export const selectIsVenuesLoading = (state: RootStateOrAny): boolean => {
  return (
    state.payMyBill.venues.fetch.isFetching ||
    state.payMyBill.venues.fetch.payload === null
  );
};

export const selectFilterTerm = (state: RootStateOrAny): string => {
  return state.payMyBill.venues.filterTerm;
};

export const selectVenueIds = (state: RootStateOrAny): number[] => {
  return state.payMyBill.venues.results.toJS();
};

export const selectVenue =
  (venueId: number) =>
  (state: RootStateOrAny): VenueSummary => {
    return state.payMyBill?.venues?.entities?.venues
      ?.get(String(venueId))
      ?.toJS();
  };
