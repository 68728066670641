/* eslint-disable react/no-array-index-key */

import { Row } from 'react-bootstrap';
import isNil from 'lodash/isNil';
import { FieldGenerator } from '.';
import { PopoverButton } from '../FormFields';
import { ConfigField } from 'types/models/Forms';

interface FormGeneratorProps {
  definition: ConfigField[];
}
/**
 * @deprecated
 */
export const FormGenerator: React.FC<FormGeneratorProps> = ({ definition }) => {
  const sortedDefinition = definition.sort((a, b) => a.row - b.row);

  return (
    <div className={definition.length < 3 ? '' : 'form-container'}>
      {sortedDefinition.map((row) => (
        <Row className="form-row" key={`form-row-${row.row}`}>
          {row.fields.map((field) => (
            <FieldGenerator key={field.name} field={field} />
          ))}
          {row.fields.map(
            (field) =>
              isNil(!field.popoverText) && (
                <PopoverButton
                  key={`${field.name}-popover`}
                  name={field.name}
                  text={field.popoverText || ''}
                  position={field.popoverPosition || ''}
                  inline
                />
              ),
          )}
        </Row>
      ))}
    </div>
  );
};
