import { useConfig } from 'contexts/ConfigContext';
import { Link } from 'react-router-dom';
import cs from 'classnames';

interface ErrorPanelProps {
  title: string;
  classNames?: string;
}

export const VenueErrorPanel: React.FC<ErrorPanelProps> = ({
  title,
  classNames,
}) => {
  const { logoLinkToHomepage, homepage } = useConfig();
  const containerClasses = cs('error-panel-contianer text-center', classNames);

  return (
    <div className={containerClasses}>
      <div className="panel panel-default">
        <h3>{title}</h3>
        <br />
        {logoLinkToHomepage ? (
          <a href={`${homepage}`} className="btn btn-primary">
            Back to homepage
          </a>
        ) : (
          <Link to="/" className="btn btn-primary">
            Back to homepage
          </Link>
        )}
      </div>
    </div>
  );
};
