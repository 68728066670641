import { useEffect } from 'react';

import { FormLayout } from 'PayMyBill/core/layouts';
import { useConfig } from 'contexts/ConfigContext';
import { useSelector, useDispatch } from 'react-redux';
import { selectBillTotalToPay } from 'PayMyBill/checkout/selectors';
import { selectTipAmount } from 'PayMyBill/bill/selectors';
import {
  redirectToBillReview,
  redirectToBillReviewIfNeeded,
  setTipAmount,
} from 'PayMyBill/bill/actions';
import { useRouteMatch, useHistory, Link } from 'react-router-dom';
import { RegisterOptions, SubmitHandler, useForm } from 'react-hook-form';
import {
  Panel,
  ButtonToolbar,
  ButtonGroup,
  InputGroup,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import { CurrencySymbol } from 'common/components/CurrencySymbol';
import {
  isNumber,
  isRequired,
  minLength,
} from 'core/components/RHF/validation';

interface FormProps {
  tipAmount: string;
}

interface ParamsProps {
  venueId: string;
  areaId: string;
  accountId: string;
}

export const AddTipPage: React.FC = () => {
  const match = useRouteMatch<ParamsProps>();
  const { venueId, areaId, accountId } = match.params;
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormProps>();

  const tipAmount = useSelector(selectTipAmount);

  useEffect(() => {
    dispatch(redirectToBillReviewIfNeeded(history, match));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue('tipAmount', Number(tipAmount)?.toFixed(2) || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    pmb: {
      addTip: { dictionary },
    },
    tipPresets,
  } = useConfig();

  const dispatch = useDispatch();

  const outstandingBalanceToPay = useSelector(selectBillTotalToPay);

  const onSubmit: SubmitHandler<FormProps> = (values) => {
    // Set tip amount in state
    dispatch(setTipAmount(values.tipAmount));
    // Redirect user to bill review page
    redirectToBillReview(history, match);
  };

  const presetSelected = (amount: string) => {
    setValue('tipAmount', amount);
  };

  const validationRules: RegisterOptions = {
    ...isRequired('Tip', true),
    ...minLength('Tip', 0),
    validate: {
      number: (value) => isNumber(value, 'Tip'),
    },
  };

  return (
    <FormLayout title={dictionary?.title || ''}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel.Body>
          {tipPresets.length > 0 && (
            <ButtonToolbar bsClass="text-center">
              <ButtonGroup>
                {tipPresets.map((v, i) => (
                  <Button
                    key={`tip-preset-button-${i}-${v}`}
                    data-testid="tip-preset-button"
                    name="tipAmount"
                    onClick={() =>
                      presetSelected(
                        (Number(outstandingBalanceToPay) * (v / 100)).toFixed(
                          2,
                        ),
                      )
                    }
                  >
                    {`${v}%`}
                  </Button>
                ))}
              </ButtonGroup>
            </ButtonToolbar>
          )}
        </Panel.Body>
        <Panel.Body>
          <div className={`form-group ${errors.tipAmount ? 'has-error' : ''}`}>
            <InputGroup>
              <InputGroup.Addon>
                <CurrencySymbol />
              </InputGroup.Addon>
              <input
                {...register('tipAmount', validationRules)}
                name="tipAmount"
                data-testid="tip-input"
                min={0}
                step="0.01"
                placeholder="Tip Amount"
                className="form-control"
              />
            </InputGroup>
            {errors && errors.tipAmount && (
              <span className="help-block" role="alert">
                {errors.tipAmount?.message}
              </span>
            )}
          </div>
          <Row>
            <Col xs={6}>
              <Link
                id="button-cancel"
                className="btn btn-default"
                data-testid="tip-cancel-button"
                to={`/pay-my-bill/venue/${venueId}/${areaId}/account/${accountId}`}
              >
                {dictionary?.buttons?.cancel}
              </Link>
            </Col>
            <Col xs={6}>
              <ButtonToolbar bsClass="text-right">
                <Button
                  id="button-confirm"
                  data-testid="tip-confirm-button"
                  type="submit"
                  bsClass="btn btn-primary"
                >
                  {dictionary?.buttons?.confirm}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Panel.Body>
      </form>
    </FormLayout>
  );
};
